import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './dashboard.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const NumberOfPassengersSardonyx = () => {
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });

    const fetchData = async () => {
        const years = [2023, 2024];
        let datasets = [];

        const colors = [
            { backgroundColor: 'rgba(255, 99, 132, 0.6)', borderColor: 'rgba(255, 99, 132, 1)' },
            { backgroundColor: 'rgba(54, 162, 235, 0.6)', borderColor: 'rgba(54, 162, 235, 1)' },
            { backgroundColor: 'rgba(75, 192, 192, 0.6)', borderColor: 'rgba(75, 192, 192, 1)' },
            { backgroundColor: 'rgba(255, 206, 86, 0.6)', borderColor: 'rgba(255, 206, 86, 1)' }
        ];

        for (const [index, year] of years.entries()) {
            const sheetName = year === 2024 ? 'Sardonyx' : `Sardonyx${year}`;
            const range = `${sheetName}!A2:J`;
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                let monthlyPassengerMap = new Array(12).fill(0);
                let monthlyNoTransferMap = new Array(12).fill(0);
                const reservationSet = new Set();
                const noTransferReservationSet = new Set();

                data.forEach(row => {
                    if (!row || !row[0]) return;
                    if (row[4] && row[4].includes("Sandals Royal")) return;

                    const dateStr = row[0];
                    let date;
                    if (year === 2024) {
                        const [day, month, yearNum] = dateStr.split('/').map(Number);
                        date = new Date(yearNum, month - 1, day);
                    } else {
                        const [month, day, yearNum] = dateStr.split('/').map(Number);
                        date = new Date(yearNum, month - 1, day);
                    }

                    if (date.getFullYear() === year) {
                        let passengers = parseInt(row[2], 10);
                        if (year === 2023) {
                            passengers = Math.round(passengers / 3);
                        }

                        if (row[6] === "No Transfer") {
                            if (!noTransferReservationSet.has(row[9])) {
                                monthlyNoTransferMap[date.getMonth()] += passengers;
                                noTransferReservationSet.add(row[9]);
                            }
                        } else {
                            if (!reservationSet.has(row[9])) {
                                monthlyPassengerMap[date.getMonth()] += passengers;
                                reservationSet.add(row[9]);
                            }
                        }
                    }
                });

                datasets.push({
                    label: `Passengers with Transfers ${year}`,
                    data: monthlyPassengerMap,
                    backgroundColor: colors[index * 2].backgroundColor,
                    borderColor: colors[index * 2].borderColor,
                    borderWidth: 1,
                    yAxisID: 'y'
                });

                datasets.push({
                    label: `Passengers without Transfers ${year}`,
                    data: monthlyNoTransferMap,
                    backgroundColor: colors[index * 2 + 1].backgroundColor,
                    borderColor: colors[index * 2 + 1].borderColor,
                    borderWidth: 1,
                    yAxisID: 'y'
                });
            } catch (error) {
                console.error(`Error fetching data for year ${year}:`, error);
            }
        }

        setChartData({
            labels: chartData.labels,
            datasets
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const refreshData = () => {
        fetchData();
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: ${context.parsed.y}`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#333',
                },
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                grid: {
                    color: '#eaeaea',
                },
                ticks: {
                    color: '#333',
                    beginAtZero: true,
                },
            },
        },
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
        },
    };

    return (
        <div className="chart-container">
            <h2 className="chart-title">Monthly Number of Passengers with Transfers (Sardonyx)</h2>
            <button onClick={refreshData}>Refresh Data</button>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default NumberOfPassengersSardonyx;
