import React, { useState, useEffect } from 'react';
import { Card, Typography, Box, Divider, Grid, CircularProgress, Paper, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import { collection, getDocs, query, orderBy, addDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 600,
  minHeight: 600,
  boxShadow: '0 12px 40px rgba(0,0,0,0.2)',
  borderRadius: '16px',
  overflow: 'hidden',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url("/images/Overlay.svg")',
    backgroundSize: 'cover',
    opacity: 0.15,
    zIndex: 0,
  }
}));

const LogoBox = styled(Box)({
  position: 'absolute',
  top: '20px',
  left: '20px',
  width: '80px',
  height: '80px',
  backgroundImage: 'url("/images/Curacao_Tours_logo.png")',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  zIndex: 2,
});

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  padding: theme.spacing(6),
  paddingTop: theme.spacing(12),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  height: '100%',
}));

const MenuTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.main,
  textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
  fontSize: '2.5rem',
}));

const MenuItemName = styled(Typography)({
  fontWeight: 600,
  fontSize: '1.2rem',
});

const MenuItemPrice = styled(Typography)({
  fontWeight: 'bold',
  textAlign: 'right',
  fontSize: '1.2rem',
  color: '#2e7d32',
});

const MenuItemPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 12px rgba(0,0,0,0.1)',
  },
}));

const prefilledData = [
  { restaurant: 'Rustiq', category: 'Appetizer', option: 'Mini beef pastechi' },
  { restaurant: 'Rustiq', category: 'Appetizer', option: 'Coconut Rice' },
  { restaurant: 'Rustiq', category: 'Appetizer', option: 'Pumpkin Soup' },
  { restaurant: 'Rustiq', category: 'Cocktail', option: 'Mosa Nena' },
  { restaurant: 'Rustiq', category: 'Cocktail', option: 'Buchi Fil' },
  { restaurant: 'Rustiq', category: 'Cocktail', option: 'Kura di Shon' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Fettucini Truffle' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Cuban Banana Soup (Creamy, Slightly spicy)' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Canneloni (Salmon, Spinach, Ricotta)' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Karni Stobá (Mushrooms, Cream, Parmesan cheese, Rucola)' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Antillian Beefstew' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Caribbean Chicken (Fillet marinated with spices, Mango Chutney)' },
  { restaurant: 'Gouverneur', category: 'Drink', option: 'Soft drink' },
  { restaurant: 'Gouverneur', category: 'Drink', option: 'Juice' },
  { restaurant: 'Gouverneur', 'category': 'Drink', option: 'Water / Sparkling water' },
  { restaurant: 'Gondola Restaurant', category: 'Dessert', option: 'Quesillo (Local style flan)' },
  { restaurant: 'Gondola Restaurant', category: 'Dessert', option: 'With Tea or Coffee' },
];

function Menuform({ bookingData }) {
  const [guestChoices, setGuestChoices] = useState({});
  const [loading, setLoading] = useState(true);
  const [menuData, setMenuData] = useState({});

  useEffect(() => {
    const processMenuData = () => {
      const processedData = prefilledData.reduce((acc, item) => {
        if (!acc[item.restaurant]) {
          acc[item.restaurant] = {};
        }
        if (!acc[item.restaurant][item.category]) {
          acc[item.restaurant][item.category] = [];
        }
        acc[item.restaurant][item.category].push(item.option);
        return acc;
      }, {});
      setMenuData(processedData);

      // Initialize guestChoices
      if (bookingData && bookingData.guests) {
        const initialGuestChoices = {};
        bookingData.guests.forEach((guest, index) => {
          initialGuestChoices[`Guest ${index + 1}`] = {};
        });
        setGuestChoices(initialGuestChoices);
      }
      setLoading(false);
    };

    processMenuData();
  }, [bookingData]);

  const handleGuestChoice = (guestNumber, restaurant, category, option) => {
    setGuestChoices(prev => ({
      ...prev,
      [guestNumber]: {
        ...prev[guestNumber],
        [restaurant]: {
          ...prev[guestNumber][restaurant],
          [category]: option
        }
      }
    }));
  };

  const handleSubmitChoices = async () => {
    try {
      const choicesData = {
        bookingId: bookingData.id,
        bookingDate: bookingData.date,
        guestChoices: guestChoices,
        submittedAt: new Date()
      };
      
      await addDoc(collection(db, 'guestChoices'), choicesData);
      alert('Choices submitted successfully!');
    } catch (error) {
      console.error('Error submitting choices: ', error);
      alert('Error submitting choices. Please try again.');
    }
  };

  if (loading) {
    return <CircularProgress size={60} />;
  }

  return (
    <StyledCard>
      <LogoBox />
      <ContentBox>
        <MenuTitle variant="h4" component="div">
          Restaurant Menu Choices
        </MenuTitle>
        
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          Booking Date: {bookingData ? new Date(bookingData.date).toLocaleDateString() : 'N/A'}
        </Typography>
        
        {Object.keys(guestChoices).map((guestNumber) => (
          <Box key={guestNumber} sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: 'text.secondary' }}>
              {guestNumber}
            </Typography>
            {Object.entries(menuData).map(([restaurant, categories]) => (
              <Box key={restaurant} sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>{restaurant}</Typography>
                {Object.entries(categories).map(([category, options]) => (
                  <FormControl key={category} fullWidth sx={{ mb: 2 }}>
                    <InputLabel>{category}</InputLabel>
                    <Select
                      value={guestChoices[guestNumber][restaurant]?.[category] || ''}
                      onChange={(e) => handleGuestChoice(guestNumber, restaurant, category, e.target.value)}
                    >
                      {options.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ))}
              </Box>
            ))}
            <Divider sx={{ my: 2 }} />
          </Box>
        ))}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 4 }}
          onClick={handleSubmitChoices}
        >
          Submit All Choices
        </Button>
      </ContentBox>
    </StyledCard>
  );
}

export default Menuform;