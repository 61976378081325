import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './dashboard.css'; // Ensure this path is correct

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const NumberOfPassengersSardonyx = () => {
    const [chartData, setChartData] = useState({
        labels: Array.from({ length: 52 }, (_, i) => `Week ${i + 1}`),
        datasets: []
    });

    const fetchData = async () => {
        const year = 2023; // Fixed year for 2023
        const range = `Sardonyx2023!A2:G`;
        const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;
        try {
            const response = await fetch(functionUrl);
            if (!response.ok) throw new Error('Failed to fetch');
            const data = await response.json();

            let weeklyPassengerMap = new Array(52).fill(0);
            data.forEach(row => {
                if (row[0]) { // Check if the date string exists
                    const [month, day, year] = row[0].split('/').map(Number); // Corrected order for MM/DD/YYYY
                    const date = new Date(year, month - 1, day); // Adjusted for zero-based month index
                    if (date.getFullYear() === year) {
                        const week = Math.ceil((date - new Date(year, 0, 1)) / (7 * 24 * 60 * 60 * 1000));
                        const passengers = parseInt(row[2], 10); // 'Total' field in column C

                        weeklyPassengerMap[week - 1] += passengers;
                    }
                }
            });

            setChartData({
                labels: chartData.labels,
                datasets: [{
                    label: 'Number of Passengers with Transfers',
                    data: weeklyPassengerMap,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                }],
            });
        } catch (error) {
            console.error('Error fetching Sardonyx2023 data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRefresh = () => {
        fetchData();
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: ${context.parsed.y}`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#333',
                },
            },
            y: {
                grid: {
                    color: '#eaeaea',
                },
                ticks: {
                    color: '#333',
                    beginAtZero: true,
                },
            },
        },
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
        },
    };

    return (
        <div className="chart-container">
            <h2 className="chart-title">Number of Passengers with Transfers (Sardonyx)</h2>
            <button onClick={handleRefresh}>Refresh</button>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default NumberOfPassengersSardonyx;
