import React from 'react';
import FormReviews from '../../components/formReviews';

const ReviewsPage = () => {
  return (
    <div>
      <FormReviews />
    </div>
  );
};

export default ReviewsPage;
