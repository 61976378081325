import React, { useState, useEffect, useCallback } from 'react';
import { Card, Typography, Button, Box, Divider, Grid, TextField, IconButton, CircularProgress, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { collection, addDoc, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import Menuform from './Menuform';

const prefilledData = [
  { restaurant: 'Rustiq', category: 'Appetizer', option: 'Mini beef pastechi' },
  { restaurant: 'Rustiq', category: 'Appetizer', option: 'Coconut Rice' },
  { restaurant: 'Rustiq', category: 'Appetizer', option: 'Pumpkin Soup' },
  { restaurant: 'Rustiq', category: 'Cocktail', option: 'Mosa Nena' },
  { restaurant: 'Rustiq', category: 'Cocktail', option: 'Buchi Fil' },
  { restaurant: 'Rustiq', category: 'Cocktail', option: 'Kura di Shon' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Fettucini Truffle' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Cuban Banana Soup (Creamy, Slightly spicy)' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Canneloni (Salmon, Spinach, Ricotta)' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Karni Stobá (Mushrooms, Cream, Parmesan cheese, Rucola)' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Antillian Beefstew' },
  { restaurant: 'Gouverneur', category: 'Main Course', option: 'Caribbean Chicken (Fillet marinated with spices, Mango Chutney)' },
  { restaurant: 'Gouverneur', category: 'Drink', option: 'Soft drink' },
  { restaurant: 'Gouverneur', category: 'Drink', option: 'Juice' },
  { restaurant: 'Gouverneur', category: 'Drink', option: 'Water / Sparkling water' },
  { restaurant: 'Gondola Restaurant', category: 'Dessert', option: 'Quesillo (Local style flan)' },
  { restaurant: 'Gondola Restaurant', category: 'Dessert', option: 'With Tea or Coffee' },
];

function MenuEditor() {
  const [guestName, setGuestName] = useState('');
  const [menuItems, setMenuItems] = useState([]);
  const [savedMenu, setSavedMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    // Set up initial data
    const uniqueRestaurants = [...new Set(prefilledData.map(item => item.restaurant))];
    setRestaurants(uniqueRestaurants);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (selectedRestaurant) {
      const restaurantCategories = [...new Set(prefilledData
        .filter(item => item.restaurant === selectedRestaurant)
        .map(item => item.category))];
      setCategories(restaurantCategories);
      setSelectedCategory('');
      setMenuItems([]);
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    if (selectedRestaurant && selectedCategory) {
      const categoryItems = prefilledData
        .filter(item => item.restaurant === selectedRestaurant && item.category === selectedCategory)
        .map(item => ({ name: item.option, price: '' }));
      setMenuItems(categoryItems);
    }
  }, [selectedRestaurant, selectedCategory]);

  const handleAddItem = () => {
    setMenuItems([...menuItems, { name: '', price: '' }]);
  };

  const handleRemoveItem = (index) => {
    const newMenuItems = menuItems.filter((_, i) => i !== index);
    setMenuItems(newMenuItems);
  };

  const handleItemChange = (index, field, value) => {
    const newMenuItems = [...menuItems];
    newMenuItems[index][field] = value;
    setMenuItems(newMenuItems);
  };

  const handleSaveMenu = async () => {
    try {
      const menuData = {
        restaurantName: selectedRestaurant,
        category: selectedCategory,
        guestName,
        menuItems,
        createdAt: new Date()
      };
      
      await addDoc(collection(db, 'menus'), menuData);
      
      alert('Menu saved successfully!');
      setSavedMenu(menuData);
    } catch (error) {
      console.error('Error saving menu: ', error);
      alert('Error saving menu. Please try again.');
    }
  };

  const renderRestaurantInput = () => (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>Select Restaurant</InputLabel>
      <Select
        value={selectedRestaurant}
        onChange={(e) => setSelectedRestaurant(e.target.value)}
      >
        {restaurants.map((restaurant) => (
          <MenuItem key={restaurant} value={restaurant}>
            {restaurant}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderCategoryInput = () => (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>Select Category</InputLabel>
      <Select
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        {categories.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderMenuItems = () => (
    menuItems.map((item, index) => (
      <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            label="Item Name"
            value={item.name}
            onChange={(e) => handleItemChange(index, 'name', e.target.value)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            label="Price"
            value={item.price}
            onChange={(e) => handleItemChange(index, 'price', e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => handleRemoveItem(index)} color="error">
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    ))
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={6}>
        <Card sx={{ boxShadow: '0 8px 24px rgba(0,0,0,0.12)', borderRadius: '4px', overflow: 'hidden' }}>
          <Box sx={{ p: 2, backgroundColor: '#E6E9F3' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Menu Editor</Typography>
            {renderRestaurantInput()}
            {selectedRestaurant && renderCategoryInput()}
            <TextField
              fullWidth
              label="Guest Name"
              value={guestName}
              onChange={(e) => setGuestName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Divider sx={{ my: 2, borderBottomWidth: 2, borderColor: 'black', opacity: 0.3 }} />
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>Menu Items:</Typography>
            {renderMenuItems()}
            <Button startIcon={<AddIcon />} onClick={handleAddItem} variant="outlined" color="primary" sx={{ mt: 1 }}>
              Add Item
            </Button>
          </Box>
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end', backgroundColor: '#D9DDED' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveMenu}
              sx={{
                backgroundColor: '#007bff',
                ':hover': { backgroundColor: '#0056b3' }
              }}
            >
              Save Menu
            </Button>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Menuform savedMenu={savedMenu} />
      </Grid>
    </Grid>
  );
}

export default MenuEditor;