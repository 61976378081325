import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const CurrentMonthRevenue = () => {
    const [revenue, setRevenue] = useState(0);
    const [previousYearRevenue, setPreviousYearRevenue] = useState(0);

    const fetchMonthRevenue = async (year, month) => {
        const range = `Bookings_${year}!A2:G`;
        const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

        try {
            const response = await fetch(functionUrl);
            if (!response.ok) throw new Error('Failed to fetch');
            const data = await response.json();

            return data.reduce((sum, row) => {
                const date = new Date(row[0]);
                const revenue = parseFloat(row[4]);
                if (date.getFullYear() === year && date.getMonth() === month && !isNaN(revenue)) {
                    return sum + revenue;
                }
                return sum;
            }, 0);
        } catch (error) {
            console.error(`Error fetching revenue for ${month + 1}/${year}:`, error);
            return 0;
        }
    };

    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        const fetchRevenues = async () => {
            const currentMonthRevenue = await fetchMonthRevenue(currentYear, currentMonth);
            const previousYearRevenue = await fetchMonthRevenue(currentYear - 1, currentMonth);

            setRevenue(currentMonthRevenue);
            setPreviousYearRevenue(previousYearRevenue);
        };

        fetchRevenues();
    }, []);

    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
    const currentMonthName = monthNames[new Date().getMonth()];

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Revenue for {currentMonthName}
                </Typography>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h5">
                        {new Date().getFullYear()}: ${revenue.toFixed(2)}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        {new Date().getFullYear() - 1}: ${previousYearRevenue.toFixed(2)}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default CurrentMonthRevenue;
