import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
// Import LanguageDetector if you still need it for other purposes

i18n
  .use(HttpApi)
  // Optionally use LanguageDetector with specific configuration if needed
  .use(initReactI18next)
  .init({
    fallbackLng: 'en', // Default language
    debug: true,
    // Remove or comment out the detection configuration to disable automatic detection
    // detection: {},
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    // Specify other options as needed
  });

export default i18n;
