import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DirectBookingsChart = () => {
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });

    useEffect(() => {
        const years = [2021, 2022, 2023, 2024]; // Specify the years you want to compare
        const barColors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']; // Different colors for each year

        const fetchDataForYear = async (year, colorIndex) => {
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=Bookings_${year}!A2:G`;
            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                const monthlyRevenue = new Array(12).fill(0);
                data.filter(row => row[1] === 'Direct Booking').forEach(row => {
                    const month = new Date(row[0]).getMonth();
                    const revenue = parseFloat(row[4]) || 0;
                    monthlyRevenue[month] += revenue;
                });

                return {
                    label: `Direct Booking (${year})`,
                    data: monthlyRevenue,
                    backgroundColor: barColors[colorIndex],
                    borderColor: barColors[colorIndex],
                };
            } catch (error) {
                console.error(`Error fetching data for ${year}:`, error);
                return {
                    label: `Direct Booking (${year})`,
                    data: new Array(12).fill(0),
                    backgroundColor: barColors[colorIndex],
                    borderColor: barColors[colorIndex],
                };
            }
        };

        const fetchDataForAllYears = async () => {
            const datasets = await Promise.all(years.map((year, index) => fetchDataForYear(year, index)));
            setChartData({ labels: chartData.labels, datasets });
        };

        fetchDataForAllYears();
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
        },
        scales: {
            x: {
                stacked: false, // Set to false for individual bars
            },
            y: {
                stacked: false, // Set to false for individual bars
                beginAtZero: true,
            },
        },
        // Adjust bar thickness and spacing
        barThickness: 'flex',
        categoryPercentage: 0.8,
        barPercentage: 0.9,
    };

    return (
        <div>
            <h2>Direct Booking Revenue Comparison by Year</h2>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default DirectBookingsChart;
