import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './dashboard.css'; // Ensure this path is correct

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const NumberOfPassengersSardonyxSandals = () => {
    const [chartData, setChartData] = useState({
        labels: Array.from({ length: 52 }, (_, i) => `Week ${i + 1}`),
        datasets: []
    });

    const fetchData = async () => {
        const year = 2024; // Only fetch data for 2024
        let dataset = [];
        let totalPassengers = new Array(52).fill(0);
        let nonServicePassengers = new Array(52).fill(0);

        const color = { backgroundColor: 'rgba(54, 162, 235, 0.6)', borderColor: 'rgba(54, 162, 235, 1)' };

        const sheetName = 'Sardonyx';
        const range = `${sheetName}!A2:J`; // Extended range to include column J for ReservationNo
        const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

        try {
            const response = await fetch(functionUrl);
            if (!response.ok) throw new Error('Failed to fetch');
            const data = await response.json();

            let weeklyPassengerMap = new Array(52).fill(0);
            const reservationSet = new Set();

            data.forEach(row => {
                if (!row || !row[0]) return;
                const [day, month, yearNum] = row[0].split('/').map(Number);
                const date = new Date(yearNum, month - 1, day);

                // Filter for 2024 and "Sandals Royal" in DestinationLocation
                if (date.getFullYear() === year && row[4] === "Sandals Royal") {
                    if (row[9] && !reservationSet.has(row[9])) {
                        reservationSet.add(row[9]);
                        const week = Math.ceil((date - new Date(year, 0, 1)) / (7 * 24 * 60 * 60 * 1000));
                        let passengers = parseInt(row[2], 10);

                        weeklyPassengerMap[week - 1] += passengers;
                        totalPassengers[week - 1] += passengers;
                        if (passengers === 0) {
                            nonServicePassengers[week - 1] += 1;
                        }
                    }
                }
            });

            dataset.push({
                label: `Passengers 2024 (Sandals Royal)`,
                data: weeklyPassengerMap,
                backgroundColor: color.backgroundColor,
                borderColor: color.borderColor,
                borderWidth: 1,
                yAxisID: 'y'
            });

            // Calculate percentage of non-service passengers
            const percentageNonService = totalPassengers.map((total, index) => {
                return total === 0 ? 0 : (nonServicePassengers[index] / total) * 100;
            });

            dataset.push({
                label: 'Percentage of PAX not using services',
                data: percentageNonService,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                type: 'line',
                yAxisID: 'y1'
            });

            setChartData({
                labels: chartData.labels,
                datasets: dataset
            });
        } catch (error) {
            console.error(`Error fetching data for year ${year}:`, error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const refreshData = () => {
        fetchData();
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: ${context.parsed.y}`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#333',
                },
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                grid: {
                    color: '#eaeaea',
                },
                ticks: {
                    color: '#333',
                    beginAtZero: true,
                },
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                ticks: {
                    color: '#333',
                    beginAtZero: true,
                },
            },
        },
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
        },
    };

    return (
        <div className="chart-container">
            <h2 className="chart-title">Number of Passengers with Transfers (Sardonyx - Sandals Royal 2024)</h2>
            <button onClick={refreshData}>Refresh Data</button>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default NumberOfPassengersSardonyxSandals;
