import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Box, Button } from '@mui/material';
import './dashboard.css'; // Ensure this path is correct

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const NumberOfPassengersSardonyxOTAlocation = () => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });

    const fetchData = async () => {
        const years = [2023, 2024];
        let combinedData = {};

        for (const year of years) {
            const sheetName = year === 2024 ? 'Sardonyx' : `Sardonyx${year}`;
            const range = `${sheetName}!A2:I`;
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                let monthlyPassengerMapByLocation = {};

                data.forEach(row => {
                    if (!row || !row[0] || !row[8]) return;
                    const dateStr = row[0];
                    const otaLocation = row[8];
                    let date;
                    if (year === 2024) {
                        const [day, month, yearPart] = dateStr.split('/').map(Number);
                        date = new Date(yearPart, month - 1, day);
                    } else {
                        const [month, day, yearPart] = dateStr.split('/').map(Number);
                        date = new Date(yearPart, month - 1, day);
                    }

                    const monthIndex = date.getMonth();
                    const monthKey = `${year}-${monthIndex + 1}`;
                    const passengers = parseInt(row[2], 10);

                    if (!monthlyPassengerMapByLocation[otaLocation]) {
                        monthlyPassengerMapByLocation[otaLocation] = {};
                    }
                    if (!monthlyPassengerMapByLocation[otaLocation][monthKey]) {
                        monthlyPassengerMapByLocation[otaLocation][monthKey] = 0;
                    }
                    monthlyPassengerMapByLocation[otaLocation][monthKey] += passengers;
                });

                Object.entries(monthlyPassengerMapByLocation).forEach(([location, dataPoints]) => {
                    if (!combinedData[location]) {
                        combinedData[location] = { 2023: {}, 2024: {} };
                    }
                    combinedData[location][year] = dataPoints;
                });
            } catch (error) {
                console.error(`Error fetching data for year ${year}:`, error);
            }
        }

        const datasets = Object.entries(combinedData).map(([location, yearData], index) => {
            const baseColor = `hsl(${360 * index / Object.keys(combinedData).length}, 70%, 50%)`;
            return [
                {
                    label: `${location} 2023`,
                    data: Object.values(yearData[2023]),
                    backgroundColor: baseColor,
                    stack: location,
                },
                {
                    label: `${location} 2024`,
                    data: Object.values(yearData[2024]),
                    backgroundColor: `${baseColor}80`, // 50% opacity
                    stack: location,
                }
            ];
        }).flat();

        setChartData({
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: datasets
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const refreshData = () => {
        fetchData();
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: ${context.parsed.y}`;
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#333',
                },
            },
            y: {
                stacked: true,
                type: 'linear',
                display: true,
                position: 'left',
                grid: {
                    color: '#eaeaea',
                },
                ticks: {
                    color: '#333',
                    beginAtZero: true,
                },
            },
        },
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
        },
    };

    return (
        <div className="chart-container">
            <Box sx={{ mt: 2 }}>
                <Button variant="contained" onClick={refreshData}>Refresh Data</Button>
            </Box>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default NumberOfPassengersSardonyxOTAlocation;