import React from 'react';
import { Helmet } from 'react-helmet'; // Import React Helmet
import HomePageTours from '../HomePageTours'; // Corrected import path
import { Grid, Container, Box, Typography, CircularProgress, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const scrollToSection = () => {
    const section = document.getElementById('target-section');
    section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <title>Curacao Tours - Discover the Beauty of Curacao</title>
        <meta name="description" content="Join Curacao Tours to explore the stunning landscapes and vibrant culture of Curacao. Book your next adventure today and create unforgettable memories." />
        <meta name="keywords" content="Curacao tours, Curacao travel, Curacao adventures, island tours, snorkeling tours, cultural tours, Curacao excursions" />
        <link rel="canonical" href="http://curacao-tours.com" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "TravelAgency",
              "name": "Curacao Tours",
              "description": "Join Curacao Tours to explore the stunning landscapes and vibrant culture of Curacao.",
              "url": "http://curacao-tours.com",
              "logo": "http://curacao-tours.com/logo192.png",
              "telephone": "+59998699559",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Seru di Mahuma #18 - 20",
                "addressLocality": "Willemstad",
                "addressRegion": "Curaçao",
                "postalCode": "0000",
                "addressCountry": "CW"
              },
              "sameAs": [
                "https://www.facebook.com/curacao.tours",
                "https://www.instagram.com/curacao.tours",
                "https://www.twitter.com/curacao.tours"
              ]
            }
          `}
        </script>
      </Helmet>

      <Container maxWidth="lg">
        <Box my={5}>
          <Typography variant={isMobile ? "h5" : "h2"} component="h1" gutterBottom align="center">
            {isMobile ? (
              <>
                Welcome to Curaçao Tours <br /> Your Gateway to Unforgettable Adventures
              </>
            ) : (
              "Welcome to Curaçao Tours – Your Gateway to Unforgettable Adventures"
            )}
          </Typography>
          <Typography variant="body1" gutterBottom align="center" sx={{ display: isMobile ? 'none' : 'block' }}>
            Discover Curaçao's vibrant beauty and rich culture with Curaçao Tours! Our expert team is dedicated to creating memorable experiences, offering a range of tours tailored to suit every traveler's taste.
          </Typography>
          <Typography variant="body1" gutterBottom align="center" sx={{ display: isMobile ? 'none' : 'block' }}>
            Choose from our comfortable, air-conditioned buses, enjoy a leisurely trolley train ride through the city's colorful streets, or opt for an active biking tour to explore Curaçao's unique landscapes. Whether you're seeking thrilling water adventures, cultural experiences, or group excursions, Curaçao Tours has the perfect option for you.
          </Typography>
          <Typography variant="body1" gutterBottom align="center" sx={{ display: isMobile ? 'none' : 'block' }}>
            Join us for an unforgettable journey and create memories that will last a lifetime. Explore our tours and start planning your adventure today!
          </Typography>
        </Box>

        <Box my={5}>
          <Typography variant={isMobile ? "h5" : "h4"} align="center" gutterBottom>
            Our Tours
          </Typography>
        </Box>
      </Container>

      {/* Target section for scrolling */}
      <div id="target-section">
        <HomePageTours />
      </div>

      <Container maxWidth="lg">
        <Box my={5}>
          <Typography variant="h4" align="center" gutterBottom>
          </Typography>
          {/* Testimonials Component could go here */}
        </Box>
      </Container>
    </>
  );
}