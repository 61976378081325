import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Container, Typography, Card, CardContent, Grid, Box } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const FormReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'surveys'));
        const reviewsData = querySnapshot.docs.map(doc => doc.data());
        setReviews(reviewsData);
        generateChartData(reviewsData);
      } catch (error) {
        console.error("Error fetching reviews: ", error);
      }
    };

    fetchReviews();
  }, []);

  const generateChartData = (reviewsData) => {
    const labels = [
      'Overall Experience', 'Tour Guide', 'Bus Cleanliness', 'Tour Punctuality',
      'Attraction Quality', 'Value for Money', 'Staff Friendliness', 'Safety Measures',
      'Overall Satisfaction', 'Likelihood to Recommend'
    ];

    const data = labels.map(label => {
      const total = reviewsData.reduce((sum, review) => sum + (parseInt(review[label.toLowerCase().replace(/ /g, '')]) || 0), 0);
      return total / reviewsData.length;
    });

    setChartData({
      labels,
      datasets: [
        {
          label: 'Average Ratings',
          data,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Reviews
      </Typography>
      <Grid container spacing={4}>
        {reviews.length > 0 ? (
          reviews.map((review, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    Tourist Type: {review.touristType}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Selected Tour: {review.selectedTour}
                  </Typography>
                  <Box mt={2}>
                    <Typography variant="body2"><strong>Overall Experience:</strong> {review.overallExperience}</Typography>
                    <Typography variant="body2"><strong>Tour Guide:</strong> {review.tourGuide}</Typography>
                    <Typography variant="body2"><strong>Bus Cleanliness:</strong> {review.busCleanliness}</Typography>
                    <Typography variant="body2"><strong>Tour Punctuality:</strong> {review.tourPunctuality}</Typography>
                    <Typography variant="body2"><strong>Attraction Quality:</strong> {review.attractionQuality}</Typography>
                    <Typography variant="body2"><strong>Value for Money:</strong> {review.valueForMoney}</Typography>
                    <Typography variant="body2"><strong>Staff Friendliness:</strong> {review.staffFriendliness}</Typography>
                    <Typography variant="body2"><strong>Safety Measures:</strong> {review.safetyMeasures}</Typography>
                    <Typography variant="body2"><strong>Overall Satisfaction:</strong> {review.overallSatisfaction}</Typography>
                    <Typography variant="body2"><strong>Likelihood to Recommend:</strong> {review.likelihoodToRecommend}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'center', width: '100%' }}>
            No reviews available.
          </Typography>
        )}
      </Grid>

      <Box mt={4}>
        <Typography variant="h4" component="h2" gutterBottom>
          Performance Chart
        </Typography>
        <Bar data={chartData} options={{ responsive: true, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Average Ratings of Reviews' } } }} />
      </Box>
    </Container>
  );
};

export default FormReviews;