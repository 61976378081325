import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppButton = () => {
  const phone = '59995153228'; // Country code + phone number without spaces or special characters
  const message = encodeURIComponent("Hello, I'd like to inquire about the tours."); // Default message
  const whatsappUrl = `https://wa.me/${phone}?text=${message}`;

  return (
    <a href={whatsappUrl} target="_blank" rel="noopener noreferrer" style={{ position: 'fixed', bottom: '30px', right: '30px', zIndex: '1000' }}>
      <button style={{ backgroundColor: '#25D366', color: 'white', padding: '10px', borderRadius: '50%', border: 'none', cursor: 'pointer' }}>
        <FontAwesomeIcon icon={faWhatsapp} size="3x" />
      </button>
    </a>
  );
};

export default WhatsAppButton;
