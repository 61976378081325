import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { getYearColor } from './colours';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MonthlyParticipantsChart = () => {
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });

    useEffect(() => {
        const years = [2021, 2022, 2023, 2024]; // Add or remove years as needed
        const fetchDataForYear = async (year) => {
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=Bookings_${year}!A2:G`;
            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                let monthlyParticipants = new Array(12).fill(0);
                data.forEach(row => {
                    const date = new Date(row[6]); // Use column G (index 6) for Tour Date
                    const month = date.getMonth();
                    const participants = parseInt(row[5]) || 0; // Use column F (index 5) for Total Participants
                    monthlyParticipants[month] += participants;
                });

                return {
                    label: `Year ${year}`,
                    data: monthlyParticipants,
                    borderColor: getYearColor(year),
                    fill: false,
                };
            } catch (error) {
                console.error(`Error fetching data for ${year}:`, error);
                return null;
            }
        };

        const fetchAllData = async () => {
            const datasets = await Promise.all(years.map(year => fetchDataForYear(year)));
            setChartData({
                labels: chartData.labels,
                datasets: datasets.filter(dataset => dataset !== null),
            });
        };

        fetchAllData();
    }, []);

    const options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div>
            <h2>Monthly Number of Participants by Year</h2>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default MonthlyParticipantsChart;
