import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Divider, Paper } from '@mui/material';

function TermsAndConditions() {
  return (
    <Container maxWidth="md" sx={{ mt: 15, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Terms & Conditions
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          of tours booked via FBTT Travel B.V.
        </Typography>
        <Divider sx={{ my: 2 }} />
        <List>
          <ListItem>
            <ListItemText
              primary="Tickets"
              primaryTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText primary="1.1 All ticket prices are quoted in US dollars." />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="1.2 Upon receipt of the tickets, the Customer must promptly verify their accuracy and follow any additional instructions provided on the tickets or accompanying email."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="1.3 The validity period of the tickets is clearly indicated. Expired tickets will not be accepted and are non-exchangeable."
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="1.4 FBTT Travel B.V. is not responsible for lost or stolen tickets." />
          </ListItem>
          <ListItem>
            <ListItemText primary="1.5 For emergencies, please call +5999 5146060." />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Cancellation Policy"
              primaryTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="2.1 Customers may cancel their booking up to 24 hours before the tour start time, unless otherwise explicitly stated."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2.2 Cancellations made within 24 hours of the expiration date of validity will not be refunded. No refunds will be issued in the case of a no-show, which will also be considered as a cancellation."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2.3 All cancellations must be submitted to and confirmed by: info@fb-tt.com."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Privacy"
              primaryTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="3.1 Personal information such as name, telephone number, and email address will only be used for order processing. This data will be used, to the extent permitted by law, for supplier management or any of their affiliates or third parties and will not be retained longer than necessary. By booking, the customer grants FBTT Travel irrevocable consent to use and store their personal data."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Liability"
              primaryTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="4.1 Participation and/or use of the supplier's services and products is at your own risk and subject to guidelines."
            />
          </ListItem>
        </List>
      </Paper>
    </Container>
  );
}

export default TermsAndConditions;
