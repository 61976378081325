export const stayoverTours = {
    'Long Stay Guests': {
      name: 'Long Stay Guests',
      tours: [
        'Beach Escape Tour',
        'E-Bike Tour',
        'Half Day Snorkel Tour',
        'Island Tour',
        'Walking Tour',
        'Trolley Tour',
        'Sunset Boat trip',
        'Culinary Vibes',
        'Shopping Tour'
      ],
    },
    'Sandals Guests': {
      name: 'Sandals Guests',
      tours: [
        'Island Tour',
        'E-Bike Tour',
        'Punda Vibes Tour',
        'Culinary Vibes Tour',
        'Walking Tour'
      ],
    },
  };