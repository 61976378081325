import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import './dashboard.css'; // Make sure this path is correct

const CurrentMonthSardonyxRevenue = () => {
    const [currentMonthRevenue, setCurrentMonthRevenue] = useState(0);
    const [ytdRevenue, setYtdRevenue] = useState(0);

    const fetchRevenue = async () => {
        const range = `Sardonyx!A2:F`;
        const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        try {
            const response = await fetch(functionUrl);
            if (!response.ok) throw new Error('Failed to fetch');
            const data = await response.json();

            let monthRevenue = 0;
            let yearToDateRevenue = 0;

            data.forEach(row => {
                const date = new Date(row[0]);
                const revenue = parseFloat(row[5]);
                if (!isNaN(revenue)) {
                    if (date.getFullYear() === currentYear) {
                        yearToDateRevenue += revenue; // Add to YTD if it's in the current year
                        if (date.getMonth() === currentMonth) {
                            monthRevenue += revenue; // Add to month's revenue if it's in the current month
                        }
                    }
                }
            });

            setCurrentMonthRevenue(monthRevenue);
            setYtdRevenue(yearToDateRevenue);
        } catch (error) {
            console.error('Error fetching revenue:', error);
        }
    };

    useEffect(() => {
        fetchRevenue();
    }, []);

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const currentMonthName = monthNames[new Date().getMonth()];

    return (
        <Card className="sardonyx-revenue-card">
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Sardonyx Revenue for {currentMonthName}
                </Typography>
                <Typography variant="h5">
                    {currentMonthName}: ${currentMonthRevenue.toFixed(2)}
                </Typography>
                <Typography variant="h5" style={{ marginTop: '8px' }}>
                    YTD: ${ytdRevenue.toFixed(2)}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CurrentMonthSardonyxRevenue;
