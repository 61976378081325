import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MonthlyRevenueChart = () => {
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });

    useEffect(() => {
        const fetchDataForYear = async (year) => {
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=Bookings_${year}!A2:G`;
            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                // Initialize an array to hold summed revenue for each month (12 months)
                const monthlyRevenue = new Array(12).fill(0);

                // Loop through each row of data
                data.forEach(row => {
                    const tourDate = row[6]; // Tour Date is in column G (index 6)
                    const revenue = parseFloat(row[4]) || 0; // Total Revenue is in column E (index 4)

                    const month = new Date(tourDate).getMonth(); // Get the month from the tour date
                    if (!isNaN(month)) {
                        monthlyRevenue[month] += revenue; // Add revenue to the correct month
                    }
                });

                return {
                    label: `Bookings ${year}`,
                    data: monthlyRevenue,
                    borderColor: getRandomColor(),
                    fill: false,
                };
            } catch (error) {
                console.error(`Error fetching data for ${year}:`, error);
                return null; // Return null or an empty object to handle errors gracefully
            }
        };
        

        const fetchAllData = async () => {
            const years = [2024, 2023, 2022, 2021];
            const datasets = (await Promise.all(years.map(year => fetchDataForYear(year)))).filter(dataset => dataset !== null);

            setChartData(prevData => ({
                ...prevData,
                datasets: datasets
            }));
        };

        fetchAllData();
    }, []);

    return (
        <div>
            <h2>Monthly Revenue</h2>
            <Line data={chartData} />
        </div>
    );
};

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export default MonthlyRevenueChart;

