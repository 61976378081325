import React, { useState, useEffect } from 'react';
import { db } from 'config/firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import TourCard from './TourCard';
import { Grid, Container, Typography, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

function RecommendedTours() {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Specify the IDs of the three tours you want to always display
  const staticTourIds = [
    "326870", "301652", "293823" // Replace these IDs with the ones you've chosen
  ];

  useEffect(() => {
    const fetchTourDetails = async () => {
      let tourDetails = [];
      try {
        for (let id of staticTourIds) {
          const activityDocRef = doc(db, 'activityDetails', id);
          const activityDocSnap = await getDoc(activityDocRef);
          const rewrittenDocRef = doc(db, 'rewrittenTourDescriptions', id);
          const rewrittenDocSnap = await getDoc(rewrittenDocRef);

          if (activityDocSnap.exists() && rewrittenDocSnap.exists()) {
            tourDetails.push({
              id: activityDocSnap.id,
              ...activityDocSnap.data(),
              ...rewrittenDocSnap.data()
            });
          } else {
            console.error(`No such document with id: ${id}`);
          }
        }
      } catch (error) {
        console.error('Error fetching recommended tour details:', error);
        setError('Failed to fetch recommended tours. Please try again later.');
      }
      setTours(tourDetails);
      setLoading(false);
    };

    fetchTourDetails();
  }, []);

  const handleLinkClick = (id) => {
    navigate(`/tour/${id}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  if (tours.length === 0) {
    return <Typography variant="subtitle1">No recommended tours available at the moment.</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={4}>
        {tours.map((tour) => (
          <Grid item key={tour.id} xs={12} sm={6} md={4}>
            <div onClick={() => handleLinkClick(tour.id)} style={{ textDecoration: 'none', cursor: 'pointer' }}>
              <TourCard tour={tour} />
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default RecommendedTours;
