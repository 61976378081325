import React from 'react';
import { Card, Grid, CardContent, Typography, Box } from '@mui/material';
import ErrorBoundary from '../../components/ErrorBoundary';
import MonthlyPaxChart from '../../components/dataconnectDashboard/MonthlyPaxChart';
import { useFirestoreCache } from '../../hooks/useFirestoreCache';
import MetadataDisplay from '../../components/dataconnectDashboard/MetadataDisplay';

const SardonyxDashboard = () => {
  const { clearCache } = useFirestoreCache();

  React.useEffect(() => {
    // Clear the cache when the dashboard first loads
    clearCache();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      {/* Header Section */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" gutterBottom component="div">
          Sardonyx Analytics Dashboard
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Real-time analytics and data visualization for Sardonyx
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* Monthly Overview Chart */}
        <Grid item xs={12}>
          <Card 
            sx={{ 
              padding: '20px', 
              boxShadow: 3, 
              backgroundColor: '#ffffff',
              minHeight: '600px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <ErrorBoundary fallback={<div>Error loading chart component</div>}>
                <MonthlyPaxChart />
              </ErrorBoundary>
            </CardContent>
          </Card>
        </Grid>

        {/* Metadata Display */}
        <Grid item xs={12}>
          <Card 
            sx={{ 
              padding: '20px', 
              boxShadow: 3, 
              backgroundColor: '#ffffff'
            }}
          >
            <CardContent>
              <ErrorBoundary fallback={<div>Error loading metadata</div>}>
                <MetadataDisplay />
              </ErrorBoundary>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SardonyxDashboard; 