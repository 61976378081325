import React, { useState, useCallback, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { Button, Box, LinearProgress, Typography } from '@mui/material';
import { getDataConnect, executeMutation } from 'firebase/data-connect';
import { initializeApp, getApp } from 'firebase/app';
import { connectorConfig, insertBookingRef, listBookings } from '@firebasegen/default-connector';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import SyncIcon from '@mui/icons-material/Sync';

// Initialize Firebase and Data Connect
const initializeDataConnect = async () => {
  try {
    const firebaseConfig = {
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    };
    
    console.log('Firebase config:', firebaseConfig);
    
    // Try to get existing app instance first
    let app;
    try {
      app = getApp();
      console.log('Using existing Firebase app');
    } catch (error) {
      // Only initialize if no app exists
      app = initializeApp(firebaseConfig);
      console.log('Created new Firebase app');
    }

    // Use the connector config from the generated SDK
    const dataConnect = await getDataConnect(app, connectorConfig);
    console.log('Data Connect initialized:', dataConnect);

    // Test connection with a simple query
    try {
      const result = await dataConnect.query(`
        query TestConnection {
          bookings(limit: 1) {
            id
            bookingRef
          }
        }
      `);
      console.log('Test query result:', result);
    } catch (error) {
      console.error('Test query failed:', error);
    }

    return dataConnect;
  } catch (error) {
    console.error('Error initializing:', error);
    throw error;
  }
};

// Optimize constants for speed
const BATCH_SIZE = 50; // Increased batch size
const MAX_CONCURRENT_CONNECTIONS = 10; // More concurrent connections
const DELAY_BETWEEN_RECORDS = 50; // Reduced delay
const DELAY_BETWEEN_BATCHES = 500; // Reduced batch delay
const MAX_RETRIES = Infinity; // Set to Infinity for unlimited retries
const INITIAL_RETRY_DELAY = 2000; // 2 second initial delay

// Add connection pool management
const connectionPool = {
  inUse: 0,
  maxSize: MAX_CONCURRENT_CONNECTIONS,
  queue: [],
  
  async acquire() {
    if (this.inUse < this.maxSize) {
      this.inUse++;
      return true;
    }
    return new Promise(resolve => this.queue.push(resolve));
  },
  
  release() {
    if (this.queue.length > 0) {
      const next = this.queue.shift();
      next(true);
    } else {
      this.inUse--;
    }
  }
};

// Helper functions
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const formatDate = (dateString) => {
  if (!dateString) {
    console.warn('Empty date string');
    return null;
  }
  
  try {
    // For dates in dd-mm-yyyy format
    const [day, month, year] = dateString.split('-');
    
    // Validate parts exist
    if (!day || !month || !year) {
      console.warn('Invalid date format:', dateString);
      return null;
    }
    
    // Pad with leading zeros if needed
    const paddedDay = day.padStart(2, '0');
    const paddedMonth = month.padStart(2, '0');
    
    // Return in YYYY-MM-DD format
    return `${year}-${paddedMonth}-${paddedDay}`;
  } catch (error) {
    console.warn('Error formatting date:', dateString, error);
    return null;
  }
};

const parsePax = (paxString) => {
  if (!paxString) return null;
  const total = paxString.split('+')
    .map(num => parseInt(num) || 0)
    .reduce((sum, num) => sum + num, 0);
  return total || null;
};

const parseAmount = (amountString) => {
  if (!amountString) return null;
  const amount = parseFloat(String(amountString).replace(/[^0-9.-]+/g, ''));
  return isNaN(amount) ? null : amount;
};

// Field mappings matching the Excel column headers exactly
const ESSENTIAL_FIELDS = {
  'date': 'date',
  'agency': 'agency',
  'agent': 'agent',
  'pax': 'pax',
  'amount': 'amount',
  'type': 'type',
  'location': 'location',
  'option': 'option'
};

const formatRowData = (row) => {
  const formattedRow = {};
  
  // Convert all keys to lowercase for case-insensitive matching
  const lowercaseRow = {};
  Object.keys(row).forEach(key => {
    lowercaseRow[key.toLowerCase()] = row[key];
  });
  
  for (const [headerName, fieldName] of Object.entries(ESSENTIAL_FIELDS)) {
    const value = lowercaseRow[headerName.toLowerCase()];
    if (value === undefined || value === '') continue;

    switch (fieldName) {
      case 'amount':
        const amount = parseAmount(value);
        if (amount !== null) formattedRow[fieldName] = amount;
        break;
      case 'pax':
        const pax = parsePax(value);
        if (pax !== null) formattedRow[fieldName] = pax;
        break;
      case 'date':
        const formattedDate = formatDate(value);
        if (formattedDate) formattedRow[fieldName] = formattedDate;
        break;
      default:
        if (value && String(value).trim()) {
          formattedRow[fieldName] = String(value).trim();
        }
    }
  }

  // Only require date field to be present
  if (!formattedRow.date) {
    console.warn('Row missing date:', row);
    return null;
  }

  return formattedRow;
};

// Update the executeInsertBooking helper function
const executeInsertBooking = async (dataConnect, variables) => {
  try {
    return executeMutation(insertBookingRef(dataConnect, variables));
  } catch (error) {
    console.error('Error executing insert:', error);
    throw error;
  }
};

// Add this helper function for chunking arrays
const chunkArray = (array, size) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

const SQLUploader = () => {
  const [dataConnect, setDataConnect] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isCancelled, setIsCancelled] = useState(false);
  const [feedback, setFeedback] = useState('Initializing...');
  const [totalRows, setTotalRows] = useState(0);
  const [processedRows, setProcessedRows] = useState(0);
  const [error, setError] = useState(null);
  const [failedRows, setFailedRows] = useState([]);
  const [currentBatch, setCurrentBatch] = useState(0);
  const [totalBatches, setTotalBatches] = useState(0);
  const [logs, setLogs] = useState([]);
  const [showDetailedLogs, setShowDetailedLogs] = useState(false);
  const [batchStatuses, setBatchStatuses] = useState({});
  const [retryAttempts, setRetryAttempts] = useState({});
  const [batchHistory, setBatchHistory] = useState({});

  // Initialize Data Connect when component mounts
  useEffect(() => {
    let mounted = true;

    const init = async () => {
      try {
        setIsInitializing(true);
        setError(null);
        const dc = await initializeDataConnect();
        
        if (mounted) {
          setDataConnect(dc);
          setFeedback('Ready to upload');
        }
      } catch (err) {
        console.error('Initialization error:', err);
        if (mounted) {
          setError(err.message);
          setFeedback(`Initialization failed: ${err.message}`);
        }
      } finally {
        if (mounted) {
          setIsInitializing(false);
        }
      }
    };

    init();

    return () => {
      mounted = false;
    };
  }, []);

  const addLog = (message, type = 'info') => {
    const timestamp = new Date().toLocaleTimeString();
    setLogs(prev => [...prev, { timestamp, message, type }]);
  };

  // Update the processRows function for better performance
  const processRows = async (rows) => {
    const startTime = Date.now();
    
    try {
      console.log('Raw rows:', rows); // Debug log
      
      const validRows = rows
        .map(row => {
          const formatted = formatRowData(row);
          if (!formatted) {
            console.log('Invalid row:', row); // Debug log
          }
          return formatted;
        })
        .filter(row => row && row.date);

      console.log('Valid rows:', validRows); // Debug log
      
      addLog(`Found ${validRows.length} valid rows out of ${rows.length} total rows`);
      
      const batches = chunkArray(validRows, BATCH_SIZE);
      setTotalBatches(batches.length);

      let processed = 0;
      let retryCount = 0;

      for (let batchIndex = 0; batchIndex < batches.length; batchIndex++) {
        if (isCancelled) throw new Error('Upload cancelled by user');

        const batch = batches[batchIndex];
        setCurrentBatch(batchIndex + 1);

        try {
          updateBatchStatus(batchIndex, 'pending');
          
          let batchSuccess = false;
          let batchRetryCount = 0;

          // Keep trying until success - no limit!
          while (!batchSuccess) {
            try {
              // Process batch with connection pooling
              const promises = batch.map(async (row) => {
                let attempts = 0;
                while (true) { // Infinite retries for each row
                  try {
                    await connectionPool.acquire();
                    await executeInsertBooking(dataConnect, {
                      date: row.date,
                      agent: row.agent || '',
                      agency: row.agency || '',
                      pax: row.pax || 0,
                      amount: row.amount || 0,
                      type: row.type || '',
                      location: row.location || '',
                      option: row.option || ''
                    });
                    return true;
                  } catch (error) {
                    attempts++;
                    if (error?.error?.code === 429 || error.message.includes('connection slots')) {
                      updateBatchStatus(batchIndex, 'retrying', attempts);
                      const waitTime = Math.pow(2, Math.min(attempts, 10)) * INITIAL_RETRY_DELAY;
                      addLog(`Row failed, attempt ${attempts}, retrying in ${waitTime/1000}s...`, 'warning');
                      await delay(waitTime);
                      continue; // Keep trying forever
                    }
                    console.error('Row error:', error);
                    return false;
                  } finally {
                    connectionPool.release();
                  }
                }
              });

              const results = await Promise.all(promises);
              const batchSuccessCount = results.filter(Boolean).length;

              if (batchSuccessCount === batch.length) {
                batchSuccess = true;
                updateBatchStatus(batchIndex, 'success');
                processed += batchSuccessCount;
                setProcessedRows(processed);
                setUploadProgress(Math.round((processed / validRows.length) * 100));
              } else {
                batchRetryCount++;
                updateBatchStatus(batchIndex, 'retrying', batchRetryCount);
                const waitTime = Math.pow(2, batchRetryCount) * INITIAL_RETRY_DELAY;
                addLog(`Batch ${batchIndex + 1} failed, retry attempt ${batchRetryCount}, waiting ${waitTime/1000}s...`, 'warning');
                await delay(waitTime);
                // No break condition - keep trying forever!
              }
            } catch (error) {
              batchRetryCount++;
              updateBatchStatus(batchIndex, 'retrying', batchRetryCount);
              const waitTime = Math.pow(2, batchRetryCount) * INITIAL_RETRY_DELAY;
              addLog(`Batch ${batchIndex + 1} error, retry attempt ${batchRetryCount}, waiting ${waitTime/1000}s...`, 'warning');
              await delay(waitTime);
              // Keep trying forever!
            }
          }

          const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
          const recordsPerSecond = processed / elapsedSeconds;
          addLog(`Batch ${batchIndex + 1}/${batches.length} - ` +
                 `${processed}/${validRows.length} (${recordsPerSecond.toFixed(1)} records/sec)`);

          await delay(DELAY_BETWEEN_BATCHES);
        } catch (error) {
          updateBatchStatus(batchIndex, 'failed');
          addLog(`Batch error, will retry: ${error.message}`, 'warning');
          batchIndex--; // Retry the entire batch
          await delay(INITIAL_RETRY_DELAY);
          continue;
        }
      }

      const totalTime = Math.floor((Date.now() - startTime) / 1000);
      const finalSpeed = processed / totalTime;
      addLog(`Complete: ${processed} records in ${totalTime}s (${finalSpeed.toFixed(1)} records/sec)`, 'success');

    } catch (error) {
      addLog(`Error: ${error.message}`, 'error');
      throw error;
    }
  };

  const handleFileUpload = useCallback(async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setFeedback('No file selected');
      return;
    }

    setIsUploading(true);
    setIsCancelled(false);
    setUploadProgress(0);
    setProcessedRows(0);
    setTotalRows(0);
    setError(null);
    setFeedback('Reading file...');

    const fileReader = new FileReader();

    fileReader.onload = async (event) => {
      try {
        let rows;

        if (file.name.endsWith('.csv')) {
          const csvData = event.target.result;
          const parsedData = Papa.parse(csvData, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          });
          rows = parsedData.data;
        } else {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          rows = XLSX.utils.sheet_to_json(firstSheet, {
            raw: false,
            dateNF: 'dd/mm/yyyy',
            defval: null,
            blankrows: false
          });
        }

        setFeedback(`Processing ${rows.length} rows...`);
        await processRows(rows);
        
      } catch (error) {
        console.error('File processing error:', error);
        setError(error.message);
        setFeedback(`Error: ${error.message}`);
      } finally {
        setIsUploading(false);
        setUploadProgress(0);
      }
    };

    fileReader.onerror = (error) => {
      console.error('FileReader error:', error);
      setError('Failed to read file');
      setFeedback('Error reading file');
      setIsUploading(false);
    };

    if (file.name.endsWith('.csv')) {
      fileReader.readAsText(file);
    } else {
      fileReader.readAsArrayBuffer(file);
    }
  }, [dataConnect, isCancelled]);

  // Update the verifyData function
  const verifyData = async () => {
    try {
      setFeedback('Verifying data...');
      addLog('Starting data verification...', 'info');

      let allBookings = [];
      let currentOffset = 0;
      const batchSize = 5000;
      let hasMore = true;

      while (hasMore) {
        addLog(`Fetching records ${currentOffset} to ${currentOffset + batchSize}...`, 'info');
        
        const result = await listBookings(dataConnect, {
          limit: batchSize,
          offset: currentOffset
        });

        if (!result?.data?.bookings) {
          throw new Error('Invalid response from database');
        }

        const batch = result.data.bookings;
        allBookings = [...allBookings, ...batch];
        
        if (batch.length < batchSize) {
          hasMore = false;
        } else {
          currentOffset += batchSize;
        }

        addLog(`Fetched ${allBookings.length} records so far...`, 'info');
      }

      const totalCount = allBookings.length;

      // Calculate statistics
      const stats = allBookings.reduce((acc, booking) => {
        acc.totalAmount += booking.amount || 0;
        acc.totalPax += booking.pax || 0;
        if (booking.agent) acc.agents.add(booking.agent);
        if (booking.location) acc.locations.add(booking.location);
        return acc;
      }, { 
        totalAmount: 0, 
        totalPax: 0, 
        agents: new Set(), 
        locations: new Set() 
      });

      // Log all the statistics
      addLog(`Complete Database Summary:`, 'success');
      addLog(`Total Records: ${totalCount}`, 'info');
      addLog(`Total Amount: $${stats.totalAmount.toFixed(2)}`, 'info');
      addLog(`Total Passengers: ${stats.totalPax}`, 'info');
      addLog(`Unique Agents: ${stats.agents.size}`, 'info');
      addLog(`Unique Locations: ${stats.locations.size}`, 'info');
      addLog(`Average Amount per Booking: $${(stats.totalAmount / totalCount).toFixed(2)}`, 'info');
      addLog(`Average Passengers per Booking: ${(stats.totalPax / totalCount).toFixed(1)}`, 'info');

      setFeedback(
        `Complete Database Summary:\n` +
        `${totalCount} total bookings\n` +
        `${stats.totalPax} total passengers (${(stats.totalPax / totalCount).toFixed(1)} avg/booking)\n` +
        `$${stats.totalAmount.toFixed(2)} total amount\n` +
        `${stats.agents.size} unique agents\n` +
        `${stats.locations.size} unique locations`
      );

    } catch (error) {
      console.error('Verification error:', error);
      addLog(`Verification failed: ${error.message}`, 'error');
      setFeedback(`Verification failed: ${error.message}`);
    }
  };

  // Add this function
  const clearDatabase = async () => {
    if (!window.confirm('Are you sure you want to delete ALL records? This cannot be undone.')) {
      return;
    }

    try {
      setFeedback('Clearing database...');
      
      await dataConnect.query(`
        mutation ClearBookings {
          booking_deleteMany(
            where: { id: { isNot: null } }
          )
        }
      `);

      setFeedback('Database cleared successfully');
      
      // Verify it's empty
      const result = await dataConnect.queries.listBookings();
      console.log('Records after clear:', result.data.bookings.length);
    } catch (error) {
      console.error('Error clearing database:', error);
      setFeedback(`Failed to clear database: ${error.message}`);
    }
  };

  // Add this helper function for status updates
  const updateBatchStatus = (batchIndex, status, retryCount = 0) => {
    setBatchStatuses(prev => ({
      ...prev,
      [batchIndex]: status
    }));
    
    if (retryCount > 0) {
      setRetryAttempts(prev => ({
        ...prev,
        [batchIndex]: retryCount
      }));
    }

    // Add to history
    setBatchHistory(prev => ({
      ...prev,
      [batchIndex]: [
        ...(prev[batchIndex] || []),
        {
          status,
          timestamp: new Date().toLocaleTimeString(),
          retryCount
        }
      ]
    }));
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
      <Typography variant="h6" gutterBottom>
        SQL Database Uploader
      </Typography>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Button
          variant="contained"
          component="label"
          disabled={isInitializing || isUploading || !dataConnect}
          color="primary"
        >
          {isInitializing ? 'Initializing...' : isUploading ? 'Uploading...' : 'Upload File'}
          <input 
            type="file" 
            accept=".csv,.xlsx,.xls" 
            onChange={handleFileUpload} 
            hidden 
            disabled={isInitializing || isUploading || !dataConnect}
          />
        </Button>

        {isUploading && (
          <>
            <LinearProgress 
              variant="determinate" 
              value={uploadProgress} 
              sx={{ my: 2 }}
            />
            <Typography variant="body2" color="text.secondary">
              {`${processedRows} of ${totalRows} records processed (${uploadProgress}%)`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`Processing batch ${currentBatch} of ${totalBatches}`}
            </Typography>
            <Button 
              variant="outlined" 
              color="error" 
              onClick={() => setIsCancelled(true)}
              sx={{ mt: 1 }}
            >
              Cancel Upload
            </Button>
          </>
        )}

        {feedback && (
          <Typography 
            color={error ? "error" : "text.secondary"} 
            sx={{ mt: 2 }}
            variant="body2"
          >
            {feedback}
          </Typography>
        )}

        {!isUploading && failedRows.length > 0 && (
          <>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                setFailedRows([]);
                processRows(failedRows);
              }}
              sx={{ mt: 2 }}
            >
              Retry Failed Rows ({failedRows.length})
            </Button>
            <Typography variant="caption" color="text.secondary">
              Click to retry uploading failed records
            </Typography>
          </>
        )}

        {!isUploading && dataConnect && (
          <Button
            variant="outlined"
            onClick={verifyData}
            sx={{ mt: 2 }}
          >
            Verify Database
          </Button>
        )}

        {!isUploading && dataConnect && (
          <Button
            variant="outlined"
            color="error"
            onClick={clearDatabase}
            sx={{ mt: 2, ml: 2 }}
          >
            Clear Database
          </Button>
        )}
      </Box>

      {/* Log Display */}
      <Box sx={{ mt: 2 }}>
        <Button 
          variant="text" 
          onClick={() => setShowDetailedLogs(!showDetailedLogs)}
          size="small"
        >
          {showDetailedLogs ? 'Hide Detailed Logs' : 'Show Detailed Logs'}
        </Button>
        
        {showDetailedLogs && (
          <Box 
            sx={{ 
              mt: 1, 
              maxHeight: 300, 
              overflowY: 'auto',
              bgcolor: 'grey.100',
              p: 2,
              borderRadius: 1
            }}
          >
            {logs.map((log, index) => (
              <Typography 
                key={index} 
                variant="body2" 
                sx={{ 
                  mb: 0.5,
                  color: log.type === 'error' ? 'error.main' : 
                         log.type === 'warning' ? 'warning.main' : 
                         log.type === 'success' ? 'success.main' : 
                         'text.primary'
                }}
              >
                {log.timestamp} - {log.message}
              </Typography>
            ))}
          </Box>
        )}
      </Box>

      {/* Batch Status Display */}
      {isUploading && (
        <Box sx={{ mt: 3, mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Batch Status:
          </Typography>
          <Box sx={{ 
            maxHeight: 300, 
            overflowY: 'auto',
            bgcolor: 'grey.50',
            p: 2,
            borderRadius: 1
          }}>
            {Object.entries(batchStatuses).map(([batchIndex, status]) => (
              <Box 
                key={batchIndex}
                sx={{ 
                  mb: 2,
                  p: 1,
                  borderRadius: 1,
                  bgcolor: 'background.paper'
                }}
              >
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  borderRadius: 1,
                  bgcolor: 
                    status === 'success' ? 'success.light' :
                    status === 'failed' ? 'error.light' :
                    status === 'retrying' ? 'warning.light' :
                    'background.paper'
                }}>
                  <Typography variant="body2" sx={{ flexGrow: 1 }}>
                    Batch {parseInt(batchIndex) + 1}: {status.charAt(0).toUpperCase() + status.slice(1)}
                    {retryAttempts[batchIndex] > 0 && 
                      ` (Attempt ${retryAttempts[batchIndex]})`
                    }
                  </Typography>
                  {status === 'success' && <CheckCircleIcon color="success" sx={{ ml: 1 }} />}
                  {status === 'failed' && <ErrorIcon color="error" sx={{ ml: 1 }} />}
                  {status === 'retrying' && <SyncIcon color="warning" sx={{ ml: 1 }} />}
                </Box>
                
                {/* History section */}
                {batchHistory[batchIndex] && batchHistory[batchIndex].length > 1 && (
                  <Box sx={{ mt: 1, pl: 2, borderLeft: '2px solid', borderColor: 'divider' }}>
                    <Typography variant="caption" color="text.secondary">
                      History:
                    </Typography>
                    {batchHistory[batchIndex].slice(0, -1).map((entry, i) => (
                      <Typography 
                        key={i} 
                        variant="caption" 
                        display="block"
                        sx={{ 
                          color: 
                            entry.status === 'success' ? 'success.main' :
                            entry.status === 'failed' ? 'error.main' :
                            entry.status === 'retrying' ? 'warning.main' :
                            'text.secondary'
                        }}
                      >
                        {entry.timestamp}: {entry.status}
                        {entry.retryCount > 0 && ` (Attempt ${entry.retryCount})`}
                      </Typography>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SQLUploader;