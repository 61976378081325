// src/theme.js
import { createTheme } from '@mui/material/styles';

// Remove the hoverEffect styles
// const hoverEffect = {
//   transition: 'transform 0.3s ease-in-out',
//   '&:hover': {
//     transform: 'scale(1.03)'
//   }
// };

// Replace the hex codes with the exact colors from your brand identity
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976D2', // Example primary color: blue
      contrastText: '#fff', // Text color for contrast
    },
    secondary: {
      main: '#FF6F61', // Example secondary color: coral
      contrastText: '#fff', // Text color for contrast
    },
    // Define additional colors
    error: {
      main: '#E53E3E', // Example error color
    },
    warning: {
      main: '#DD6B20', // Example warning color
    },
    info: {
      main: '#3182CE', // Example info color
    },
    success: {
      main: '#38A169', // Example success color
    },
    background: {
      default: '#f0f0f0', // Light grey background for the entire application
      paper: '#ffffff', // Background for elements like paper, cards
    },
    text: {
      primary: '#333', // Primary text color
      secondary: '#555', // Secondary text color
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Arial',
      'sans-serif'
    ].join(','),
    // You can add more typography options here
  },

  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          // Remove hoverEffect from Card
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Remove hoverEffect from Button
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // Remove hoverEffect from Paper
        }
      }
    },
    
    MuiAppBar: {
      styleOverrides: {
        root: {
          // Remove hover effect for AppBar
        }
      }
    },
  }
});

export default theme;
