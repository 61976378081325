// src/components/ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    const password = prompt('Please enter the password to access this page:');
    const correctPassword = 'admin'; // Replace 'yourPassword' with your actual password

    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password!');
    }
    setIsAuthChecked(true);
  }, []);

  if (!isAuthChecked) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
