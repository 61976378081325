import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Grid, Typography, TextField, Button, Box, Paper, useTheme, useMediaQuery, Link
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function ContactPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const googleMapsUrl = "https://www.google.com/maps/place/Seru+di+Mahuma+18-20,+Willemstad,+Curaçao/";

  // Define our new color palette
  const colors = {
    primary: '#33A5DB', // The main vibrant blue
    secondary: '#66C1E8', // A lighter blue
    background: '#E6F5FB', // The lightest blue for backgrounds
    text: '#1A5270', // A dark blue for text
  };

  const heroImageStyle = {
    width: '100%',
    height: '50vh',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
  };

  const sectionStyle = {
    padding: theme.spacing(6, 4),
    backgroundColor: colors.background,
    borderRadius: theme.shape.borderRadius,
    boxShadow: `0 8px 32px 0 ${colors.secondary}33`,
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: `0 12px 48px 0 ${colors.secondary}66`,
    },
  };

  const iconStyle = {
    marginRight: theme.spacing(2),
    color: colors.primary,
  };

  return (
    <Box sx={{ backgroundColor: colors.background, minHeight: '100vh', py: 8 }}>
      <Container maxWidth="lg">
        <Helmet>
          {/* ... Helmet content remains the same ... */}
        </Helmet>

        <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ mb: 6, color: colors.primary, fontWeight: 'bold' }}>
          Contact Us
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Box sx={{ overflow: 'hidden', borderRadius: theme.shape.borderRadius, boxShadow: `0 8px 32px 0 ${colors.secondary}33` }}>
              <img
                src="/images/Curacao_Tours_Contact.jpg"
                alt="FBTT Travel Curacao"
                style={heroImageStyle}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={sectionStyle}>
              <Typography variant="h4" gutterBottom sx={{ color: colors.text, fontWeight: 'bold', mb: 4 }}>
                Get in Touch
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <LocationOnIcon sx={iconStyle} />
                <Typography variant="body1" sx={{ color: colors.text }}>
                  Seru di Mahuma #18 - 20, Willemstad, Curaçao
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Link href={googleMapsUrl} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center', color: colors.primary }}>
                  View on Google Maps
                </Link>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <PhoneIcon sx={iconStyle} />
                <Typography variant="body1" sx={{ color: colors.text }}>+5999 8699559</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <WhatsAppIcon sx={iconStyle} />
                <Typography variant="body1" sx={{ color: colors.text }}>+5999 5146060</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon sx={iconStyle} />
                <Typography variant="body1" sx={{ color: colors.text }}>info@curacao-tours.com</Typography>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={sectionStyle}>
              <Typography variant="h4" gutterBottom sx={{ color: colors.text, fontWeight: 'bold', mb: 4 }}>
                Send Us a Message
              </Typography>
              <form noValidate autoComplete="off">
                <TextField fullWidth label="Name" variant="outlined" margin="normal" required />
                <TextField fullWidth label="Email Address" variant="outlined" margin="normal" required />
                <TextField fullWidth label="Phone Number" variant="outlined" margin="normal" />
                <TextField fullWidth label="Subject" variant="outlined" margin="normal" />
                <TextField fullWidth label="Message" variant="outlined" margin="normal" required multiline rows={4} />
                <Button 
                  variant="contained" 
                  size="large"
                  sx={{ 
                    mt: 3, 
                    fontWeight: 'bold', 
                    padding: theme.spacing(1.5, 4),
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: colors.primary,
                    '&:hover': {
                      backgroundColor: colors.secondary,
                    }
                  }}
                >
                  Send Message
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ContactPage;
