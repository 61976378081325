export const chartColors = {
    blue: '#4e79a7',
    orange: '#f28e2c',
    red: '#e15759',
    teal: '#76b7b2',
    green: '#59a14f',
    yellow: '#edc949',
    purple: '#af7aa1',
    pink: '#ff9da7',
    brown: '#9c755f',
    gray: '#bab0ab'
};

export const yearColors = {
    2020: '#4e79a7', // blue
    2021: '#f28e2c', // orange
    2022: '#e15759', // red
    2023: '#76b7b2', // teal
    2024: '#59a14f', // green
    2025: '#edc949', // yellow
    2026: '#af7aa1', // purple
    2027: '#ff9da7', // pink
    2028: '#9c755f', // brown
    2029: '#bab0ab', // gray
};

export const getYearColor = (year) => {
    if (yearColors.hasOwnProperty(year)) {
        return yearColors[year];
    }
    const colors = Object.values(chartColors);
    return colors[year % colors.length];
};