import { getFirestore, doc, getDoc, setDoc, collection, getDocs, writeBatch } from 'firebase/firestore';
import { getApp } from 'firebase/app';

export const useFirestoreCache = () => {
  const db = getFirestore(getApp());

  const getCachedData = async (cacheKey) => {
    try {
      const docRef = doc(db, 'analyticsCache', cacheKey);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        // Check if cache is still valid (less than 24 hours old)
        if (data.timestamp && (Date.now() - data.timestamp) < 24 * 60 * 60 * 1000) {
          console.log('Using cached data for:', cacheKey);
          // Ensure the data structure is correct
          return {
            data: {
              monthlyBookingStatss: data.value?.monthlyBookingStatss || [],
              agents: data.value?.agents || []
            }
          };
        }
      }
      return null;
    } catch (error) {
      console.error('Error reading cache:', error);
      return null;
    }
  };

  const setCachedData = async (cacheKey, data) => {
    try {
      // Ensure we're storing the data in the correct structure
      const valueToStore = {
        monthlyBookingStatss: data?.monthlyBookingStatss || [],
        agents: data?.agents || []
      };

      const docRef = doc(db, 'analyticsCache', cacheKey);
      await setDoc(docRef, {
        value: valueToStore,
        timestamp: Date.now(),
        lastDate: new Date().toISOString().split('T')[0]
      });
      console.log('Data cached for:', cacheKey, valueToStore);
    } catch (error) {
      console.error('Error writing cache:', error);
    }
  };

  const clearCache = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'analyticsCache'));
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
      console.log('Cache cleared successfully');
    } catch (error) {
      console.error('Error clearing cache:', error);
    }
  };

  return {
    getCachedData,
    setCachedData,
    clearCache
  };
}; 