import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  Legend
} from 'recharts';
import { useDataConnect } from '../../hooks/useDataConnect';
import { useFirestoreCache } from '../../hooks/useFirestoreCache';

const MonthlyPaxChart = () => {
  const { executeQuery, loading: connectLoading, error: connectError, ready } = useDataConnect();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dataFetched = useRef(false);
  const { getCachedData } = useFirestoreCache();

  useEffect(() => {
    const fetchData = async () => {
      if (!ready || dataFetched.current) return;
      
      try {
        setLoading(true);
        console.log('Fetching monthly stats...');
        const result = await executeQuery('MonthlyStats');
        console.log('Raw result:', result);

        // Validate and transform the data
        const monthlyStats = result?.data?.monthlyBookingStatss || [];
        if (!Array.isArray(monthlyStats)) {
          console.error('Invalid data structure:', result);
          throw new Error('Invalid data format received');
        }

        console.log('Monthly stats:', monthlyStats);
        
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                       'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        
        // Group data by year
        const statsByYear = monthlyStats.reduce((acc, stat) => {
          const year = stat.year;
          if (!acc[year]) {
            acc[year] = new Array(12).fill(null);
          }
          acc[year][Number(stat.month) - 1] = stat;
          return acc;
        }, {});

        // Create comparison data structure
        const chartData = months.map((month, index) => ({
          month,
          "2024": {
            pax: statsByYear['2024']?.[index]?.totalPax || 0,
            bookings: statsByYear['2024']?.[index]?.bookingCount || 0,
            amount: statsByYear['2024']?.[index]?.totalAmount || 0
          },
          "2023": {
            pax: statsByYear['2023']?.[index]?.totalPax || 0,
            bookings: statsByYear['2023']?.[index]?.bookingCount || 0,
            amount: statsByYear['2023']?.[index]?.totalAmount || 0
          }
        }));

        setData(chartData);
        dataFetched.current = true;
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [ready, executeQuery]);

  // Calculate totals for comparison
  const totals = data.reduce((acc, item) => ({
    "2024": {
      pax: acc["2024"].pax + item["2024"].pax,
      bookings: acc["2024"].bookings + item["2024"].bookings,
      amount: acc["2024"].amount + item["2024"].amount
    },
    "2023": {
      pax: acc["2023"].pax + item["2023"].pax,
      bookings: acc["2023"].bookings + item["2023"].bookings,
      amount: acc["2023"].amount + item["2023"].amount
    }
  }), {
    "2024": { pax: 0, bookings: 0, amount: 0 },
    "2023": { pax: 0, bookings: 0, amount: 0 }
  });

  if (connectLoading || loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || connectError) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error || connectError}
      </Alert>
    );
  }

  return (
    <Box sx={{ width: '100%', height: '100%', minHeight: 500 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Monthly Passengers: 2023 vs 2024
        </Typography>
      </Box>
      
      <Typography variant="body2" color="text.secondary" gutterBottom>
        {`Total Passengers: ${totals["2023"].pax.toLocaleString()} (2023) vs ${totals["2024"].pax.toLocaleString()} (2024)`}
      </Typography>

      {data.length > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="month" 
              angle={-45}
              textAnchor="end"
              height={70}
              interval={0}
            />
            <YAxis />
            <Tooltip 
              formatter={(value, name) => {
                return [`${value.toLocaleString()} ${name.includes('bookings') ? 'bookings' : 'passengers'}`, 
                  name];
              }}
            />
            <Legend />
            <Bar dataKey="2023.pax" fill="#8884d8" name="2023 Passengers" />
            <Bar dataKey="2024.pax" fill="#82ca9d" name="2024 Passengers" />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Box sx={{ 
          height: 400, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          border: '1px dashed #ccc' 
        }}>
          <Typography color="text.secondary">No data available</Typography>
        </Box>
      )}

      {/* Add percentage change indicators */}
      <Box sx={{ mt: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        {data.map((month, index) => {
          const pax2023 = month["2023"].pax;
          const pax2024 = month["2024"].pax;
          const percentChange = pax2023 ? ((pax2024 - pax2023) / pax2023 * 100).toFixed(1) : 0;
          
          return (
            <Box key={month.month} sx={{ 
              p: 1, 
              bgcolor: 'background.paper',
              borderRadius: 1,
              border: 1,
              borderColor: 'divider'
            }}>
              <Typography variant="caption" display="block">
                {month.month}
              </Typography>
              <Typography 
                variant="body2" 
                color={percentChange > 0 ? 'success.main' : 'error.main'}
              >
                {percentChange > 0 ? '+' : ''}{percentChange}%
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default MonthlyPaxChart;