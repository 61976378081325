import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import './dashboard.css'; // Ensure this path is correct

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const RevenuePerYearChartSardonyx = () => {
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });

    const fetchData = async () => {
        const years = [2024]; // Define the years you want to analyze
        let datasets = [];

        for (const year of years) {
            const range = `Sardonyx!A2:F`;
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                let monthlyRevenue = new Array(12).fill(0);
                let monthlyPassengers = new Array(12).fill(0);

                data.forEach(row => {
                    const [day, month, year] = row[0].split('/').map(Number); // Adjusted for DD/MM/YYYY format
                    const date = new Date(year, month - 1, day); // Adjust month index
                    if (date.getFullYear() === year) {
                        const monthIndex = date.getMonth(); // Get the month (0-indexed)
                        const revenue = parseFloat(row[5]); // 'Amount' column for revenue
                        const passengers = parseInt(row[2], 10); // 'Total' column for passengers

                        if (!isNaN(revenue)) {
                            monthlyRevenue[monthIndex] += revenue;
                        }
                        if (!isNaN(passengers)) {
                            monthlyPassengers[monthIndex] += passengers;
                        }
                    }
                });

                // Revenue dataset
                datasets.push({
                    label: `Revenue ${year}`,
                    data: monthlyRevenue,
                    borderColor: getRandomColor(years.indexOf(year) * 2), // Ensure unique colors
                    backgroundColor: 'transparent',
                    yAxisID: 'y',
                });

                // Passengers dataset
                datasets.push({
                    label: `Passengers ${year}`,
                    data: monthlyPassengers,
                    borderColor: getRandomColor(years.indexOf(year) * 2 + 1), // Ensure unique colors
                    backgroundColor: 'transparent',
                    borderDash: [5, 5], // Dashed line for passengers
                    yAxisID: 'y1',
                });
            } catch (error) {
                console.error(`Error fetching data for year ${year}:`, error);
            }
        }

        setChartData({
            labels: chartData.labels,
            datasets
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRefresh = () => {
        fetchData();
    };

    const chartOptions = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                position: 'nearest',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                  display: true,
                  text: 'Revenue ($)'
                },
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false, // Only show the grid for the passenger scale
                },
                title: {
                  display: true,
                  text: 'Passengers'
                },
                // Ensure the passenger axis aligns with the main axis
                alignTo: 'y',
            },
        },
    };

    return (
        <div className="chart-container">
            <h2 className="chart-title">Monthly Revenue & Passengers Per Year (Sardonyx)</h2>
            <button onClick={handleRefresh}>Refresh</button>
            <Line data={chartData} options={chartOptions} />
        </div>
    );
};

// Utility function to generate colors for the line chart
const getRandomColor = (index) => {
    const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#CDDC39', '#00BCD4', '#9C27B0', '#F44336', '#3F51B5', '#E91E63'];
    return colors[index % colors.length];
};

export default RevenuePerYearChartSardonyx;
