import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './dashboard.css'; // Ensure this path is correct

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Utility function to generate distinct colors for each agent
const getRandomColor = (index) => {
    const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
    return colors[index % colors.length];
};

const MonthlyRevenueByAgentSardonyx = () => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });

    useEffect(() => {
        const fetchData = async (year) => {
            const range = `Sardonyx!A2:F`;
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;
            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                let agentRevenueMap = {};

                data.forEach(row => {
                    const [day, month, year] = row[0].split('/').map(Number);
                    const date = new Date(year, month - 1, day); // Adjust month index
                    if (date.getFullYear() === selectedYear) {
                        const monthIndex = date.getMonth();
                        const agentName = row[3];
                        const revenue = parseFloat(row[5]);

                        if (!agentRevenueMap[agentName]) {
                            agentRevenueMap[agentName] = new Array(12).fill(0);
                        }
                        agentRevenueMap[agentName][monthIndex] += revenue;
                    }
                });

                const datasets = Object.keys(agentRevenueMap).map((agentName, index) => ({
                    label: agentName,
                    data: agentRevenueMap[agentName],
                    backgroundColor: getRandomColor(index),
                }));

                setChartData({
                    labels: chartData.labels,
                    datasets,
                });
            } catch (error) {
                console.error('Error fetching Sardonyx data:', error);
            }
        };

        fetchData(selectedYear);
    }, [selectedYear]);

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                enabled: true,
                mode: 'point',
                position: 'nearest',
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: $${context.parsed.y}`;
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: false,
                ticks: {
                    color: '#333',
                },
                grid: {
                    color: 'transparent',
                },
            },
            y: {
                stacked: false,
                beginAtZero: true,
                ticks: {
                    color: '#333',
                    callback: function(value) {
                        return '$' + value;
                    },
                },
                grid: {
                    color: '#eaeaea',
                },
            },
        },
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
        },
    };

    return (
        <div className="chart-container">
            <h2 className="chart-title">Monthly Revenue by Agent (Sardonyx)</h2>
            <div className="chart-controls">
                <label htmlFor="year-select-sardonyx" className="chart-label">Select Year:</label>
                <select id="year-select-sardonyx" className="chart-select" value={selectedYear} onChange={handleYearChange}>
                    {[2021, 2022, 2023, 2024].map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default MonthlyRevenueByAgentSardonyx;
