import React from 'react';
import { Helmet } from 'react-helmet'; // Import React Helmet
import HomePageTours from '../HomePageTours'; // Corrected import path
import CuracaoMap from '../../components/map'; // Import the CuracaoMap component
import { Grid, Container, Box, Typography, CircularProgress, Button } from '@mui/material';

export default function Home() {
  const scrollToSection = () => {
    const section = document.getElementById('target-section');
    section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <title>Curacao Tours - Discover the Beauty of Curacao</title>
        <meta name="description" content="Join Curacao Tours to explore the stunning landscapes and vibrant culture of Curacao. Book your next adventure today and create unforgettable memories." />
        <meta name="keywords" content="Curacao tours, Curacao travel, Curacao adventures, island tours, snorkeling tours, cultural tours, Curacao excursions" />
        <link rel="canonical" href="http://curacao-tours.com" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "TravelAgency",
              "name": "Curacao Tours",
              "description": "Join Curacao Tours to explore the stunning landscapes and vibrant culture of Curacao.",
              "url": "http://curacao-tours.com",
              "logo": "http://curacao-tours.com/logo192.png",
              "telephone": "+59998699559",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Seru di Mahuma #18 - 20",
                "addressLocality": "Willemstad",
                "addressRegion": "Curaçao",
                "postalCode": "0000",
                "addressCountry": "CW"
              },
              "sameAs": [
                "https://www.facebook.com/curacao.tours",
                "https://www.instagram.com/curacao.tours",
                "https://www.twitter.com/curacao.tours"
              ]
            }
          `}
        </script>
      </Helmet>

      <Container maxWidth="lg">
        <Box my={5}>
          <Typography variant="h2" component="h1" gutterBottom align="center">
            Explore Curaçao with Us
          </Typography>
          <Typography variant="h6" component="p" gutterBottom align="center">
            Discover the island's hidden gems with our curated tours.
          </Typography>
        </Box>

        {/* Add the CuracaoMap component here */}
        <Box my={5}>
          <Typography variant="h4" align="center" gutterBottom>
            Our Tour Locations
          </Typography>
          <CuracaoMap />
        </Box>
      </Container>

      {/* Target section for scrolling */}
      <div id="target-section">
        <HomePageTours />
      </div>

      <Container maxWidth="lg">
        <Box my={5}>
          <Typography variant="h4" align="center" gutterBottom>
          </Typography>
          {/* Testimonials Component could go here */}
        </Box>
      </Container>
    </>
  );
}
