import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import './dashboard.css'; // Ensure this path is correct

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const NumberOfPassengersSardonyxSandals = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    const fetchData = async () => {
        const fetch2024Data = async () => {
            const range = `Sardonyx!A2:G`;
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                let monthlyData = Array(12).fill().map(() => ({ private: 0, regular: 0 }));

                data.forEach(row => {
                    if (!row || row.length < 7) return;
                    const [day, month, yearNum] = row[0].split('/').map(Number);
                    const date = new Date(yearNum, month - 1, day);

                    // Check if the DestinationLocation (column F, index 5) does not contain "CUR"
                    if (date.getFullYear() === 2024 && row[1] === "Sandals" && !row[5].includes("CUR")) {
                        const passengers = parseInt(row[2], 10) || 0;
                        const isPrivate = row[6] === 'Private';
                        
                        if (isPrivate) {
                            monthlyData[month - 1].private += passengers;
                        } else {
                            monthlyData[month - 1].regular += passengers;
                        }
                    }
                });

                return monthlyData;
            } catch (error) {
                console.error(`Error fetching 2024 data:`, error);
                return Array(12).fill().map(() => ({ private: 0, regular: 0 }));
            }
        };

        const fetch2023Data = async () => {
            const range = `Sardonyx2023!A2:I`;
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                let monthlyData = Array(12).fill(0);

                data.forEach(row => {
                    if (!row || row.length < 3) return;
                    const [month, day, year] = row[0].split('/').map(Number);
                    const date = new Date(year, month - 1, day);

                    if (date.getFullYear() === 2023 && row[1] === "Sandals") {
                        const passengers = parseInt(row[2], 10) || 0;
                        monthlyData[month - 1] += passengers;
                    }
                });

                console.log('2023 Monthly Data:', monthlyData); // For debugging

                return monthlyData;
            } catch (error) {
                console.error(`Error fetching 2023 data:`, error);
                return Array(12).fill(0);
            }
        };

        const data2024 = await fetch2024Data();
        const data2023 = await fetch2023Data();

        const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        setChartData({
            labels: labels,
            datasets: [
                {
                    type: 'bar',
                    label: '2024 Private Transfers',
                    data: data2024.map(m => m.private),
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1
                },
                {
                    type: 'bar',
                    label: '2024 Regular Transfers',
                    data: data2024.map(m => m.regular),
                    backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1
                },
                {
                    type: 'line',
                    label: '2023 Total Transfers',
                    data: data2023,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 2,
                    fill: false,
                    tension: 0.1
                }
            ]
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const refreshData = () => {
        fetchData();
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#333',
                },
            },
            y: {
                grid: {
                    color: '#eaeaea',
                },
                ticks: {
                    color: '#333',
                    beginAtZero: true,
                },
            },
        },
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
        },
    };

    return (
        <div className="chart-container">
            <h2 className="chart-title">Private vs Regular Transfers for Sandals (2024) with 2023 Total</h2>
            <button onClick={refreshData}>Refresh Data</button>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default NumberOfPassengersSardonyxSandals;
