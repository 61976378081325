import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box, Button, Menu, MenuItem, useTheme, useMediaQuery, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import WeatherWidget from './WeatherWidget';
import { db } from 'config/firebase/firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

function Header({ margin = '0', height = '100px' }) {
  const theme = useTheme();
  const location = useLocation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // This will be true for desktop devices
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // This will be true for mobile devices

  // State for menu
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl); // Determines if the dropdown is open

  const [menuCategories, setMenuCategories] = useState({});

  useEffect(() => {
    const fetchMenuItems = async () => {
      const q = query(collection(db, 'activitySelections'), where('homePage', '==', true));
      const querySnapshot = await getDocs(q);
      const items = await Promise.all(querySnapshot.docs.map(async docSnapshot => {
        const activityDoc = await getDoc(doc(db, 'activityDetails', docSnapshot.id));
        return {
          title: activityDoc.exists() ? activityDoc.data().title : 'No Title',
          link: `/tour/${docSnapshot.id}`
        };
      }));

      console.log('Fetched tours:', items); // Log the fetched tours

      const categories = {
        'Explore': ['Beach Escape', 'Curaçao Island Tour', 'Historic Walking Tour', 'Trolley Train City Centre'],
        'Water Tours': ['SeaWorld Explorer', 'Half Day Snorkel Tour by Boat', 'Sunset Cruise'],
        'Experiential': ['Punda Vibes E-Bike experience', 'City e-Bike Tour', 'Shopping Tour', 'Culinary Tour']
      };

      const categorizedItems = Object.keys(categories).reduce((acc, category) => {
        acc[category] = items.filter(item => categories[category].includes(item.title));
        return acc;
      }, {});

      setMenuCategories(categorizedItems);
    };

    fetchMenuItems();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Adjust styles for the navigation links to use theme colors
  const linkStyle = (isActive) => ({
    textDecoration: 'none',
    margin: '0 10px',
    padding: '5px 10px',
    borderRadius: '4px',
    transition: 'background-color 0.3s, color 0.3s',
    color: isActive ? 'white' : theme.palette.text.primary,
    fontWeight: 'bold',
    backgroundColor: isActive ? '#1E388B' : 'transparent', // Dark blue background only when active
  });

  // Style for the dropdown trigger button
  const dropdownButtonStyle = {
    textDecoration: 'none',
    margin: '0 10px',
    padding: '5px 10px',
    borderRadius: '0',
    transition: 'background-color 0.3s, color 0.3s',
    color: isMenuOpen ? 'white' : theme.palette.text.primary,
    backgroundColor: isMenuOpen ? '#1E388B' : 'transparent', // Dark blue when the menu is open
    fontWeight: 'bold',
    marginBottom: isMenuOpen ? '4px' : '0'
  };

  const menuItemStyle = {
    color: '#ACB7D1', // Light purple text color
    justifyContent: 'center',
    backgroundColor: '#1E388B', // Dark blue background
    '&:hover': {
      backgroundColor: '#162955' // Slightly darker on hover
    },
    padding: '10px 20px',
    textAlign: 'left' // Align text to the left
  };

  return (
    <AppBar position="sticky" color="default" elevation={1} sx={{ background: 'white', boxShadow: theme.shadows[2], margin, height, top: 0, zIndex: 1100 }}>
      <Toolbar sx={{ 
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : '1fr 2fr 1fr',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative', 
        height 
      }}>
        {location.pathname !== '/dashboard' && (
          <Box sx={{ gridColumn: '1 / 2' }}>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <img
                src="/images/Curacao_Tours_logo.png"
                alt="Curacao Tours Logo"
                style={{
                  maxHeight: isMobile ? '80px' : '250px',
                  height: 'auto',
                  position: 'absolute',
                  top: isMobile ? '5px' : '-50px',
                  zIndex: 2,
                  left: isMobile ? '10px' : '50px',
                }}
              />
            </Link>
          </Box>
        )}
        {isDesktop && (
          <Box sx={{ gridColumn: '2 / 3', display: 'flex', justifyContent: 'center' }}>
            <Button 
              component={Link} 
              to="/" 
              sx={linkStyle(location.pathname === '/')}
            >
              HOME
            </Button>
            <Button
              onClick={handleClick}
              sx={dropdownButtonStyle}
            >
              ALL TOURS {isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  maxWidth: '800px', // Adjust this value as needed
                  width: '100%',
                },
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                sx: {
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  backgroundColor: '#1E388B', // Dark blue background
                  color: '#ACB7D1', // Light purple text color
                  borderRadius: '5px',
                  boxShadow: theme.shadows[3],
                  marginTop: '4px',
                }
              }}
            >
              {Object.entries(menuCategories).map(([category, items]) => (
                <Box key={category}>
                  <MenuItem 
                    sx={{ 
                      ...menuItemStyle, 
                      fontWeight: 'bold', 
                      backgroundColor: '#ffffff',
                      color: theme.palette.primary.main, // Change this to your desired color
                      opacity: 0.7 // Set the desired opacity value here
                    }}
                  >
                    {category}
                  </MenuItem>
                  {items.map(item => (
                    <MenuItem 
                      key={item.title} 
                      sx={menuItemStyle} 
                      onClick={handleClose} 
                      component={Link} 
                      to={item.link}
                    >
                      • {item.title}
                    </MenuItem>
                  ))}
                </Box>
              ))}
              <Box sx={{ 
                gridColumn: '1 / -1', 
                padding: '10px', 
                backgroundColor: 'white', 
                textAlign: 'center',
                opacity: 0.7 // Set the desired opacity value here
              }}>
                <Typography variant="body2" sx={{ color: 'blue', fontWeight: 'bold' }}>
                  Groups:
                </Typography>
                <Typography variant="body2" sx={{ color: 'blue' }}>
                  For group inquiries, please contact Groups@Fb-tt.com.
                </Typography>
                <Typography variant="body2" sx={{ color: 'blue', fontSize: '0.8rem', marginTop: '5px' }}>
                  Plan the perfect group tour with Curaçao Tours! Contact us at groups@fb-tt.com or call 8699559 for exclusive group packages and custom itineraries. Book now for a unique experience!
                </Typography>
              </Box>
            </Menu>
            <Button component={Link} to="/local-deals" sx={linkStyle(location.pathname === '/local-deals')}>LOCAL DEALS</Button>
            <Button component={Link} to="/transfers" sx={linkStyle(location.pathname === '/transfers')}>TRANSFERS</Button>
            <Button component={Link} to="/contact" sx={linkStyle(location.pathname === '/contact')}>CONTACT</Button>
          </Box>
        )}
        {!isMobile && (
          <Box sx={{ gridColumn: '3 / 4', display: 'flex', justifyContent: 'flex-end', marginRight: '50px' }}>
            <WeatherWidget />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;