import { useState, useEffect, useRef } from 'react';
import { getDataConnect } from 'firebase/data-connect';
import { getApp, initializeApp } from 'firebase/app';
import { 
  connectorConfig, 
  monthlyStats,
  agentMonthlyStats,
  listBookings,
  fetchMetadata
} from '@firebasegen/default-connector';
import { useFirestoreCache } from './useFirestoreCache';

const logInfo = (message, data = {}) => {
  console.log(`[Data Connect] ${message}:`, data);
};

export const useDataConnect = () => {
  const [dataConnect, setDataConnect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const initialized = useRef(false);
  const { getCachedData, setCachedData } = useFirestoreCache();

  useEffect(() => {
    const initializeDataConnect = async () => {
      if (initialized.current) return;
      initialized.current = true;

      try {
        const firebaseConfig = {
          projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
          apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
          authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        };

        let app;
        try {
          app = getApp();
        } catch {
          app = initializeApp(firebaseConfig);
        }

        const dc = await getDataConnect(app, connectorConfig);
        
        // Test if we can actually use the connection
        const testResult = await monthlyStats(dc);
        if (!testResult?.data) {
          throw new Error('Connection test failed');
        }

        setDataConnect(dc);
        setLoading(false);
        logInfo('Successfully initialized');
      } catch (err) {
        console.error('Failed to initialize:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    initializeDataConnect();
  }, []);

  const executeQuery = async (queryName, variables = {}) => {
    if (!dataConnect) {
      throw new Error('Data Connect not initialized');
    }

    try {
      console.log(`Executing query: ${queryName}`, { variables });

      let result;
      switch (queryName) {
        case 'MonthlyStats': {
          result = await monthlyStats(dataConnect);
          break;
        }
        case 'AgentMonthlyStats': {
          result = await agentMonthlyStats(dataConnect, variables);
          break;
        }
        case 'FetchMetadata': {
          result = await listBookings(dataConnect);
          
          // Transform the result to match expected format
          const bookings = result.data.bookings;
          const agentCounts = {};
          const uniqueMetadata = {
            agents: new Map(),
            types: new Set(),
            options: new Set(),
            locations: new Set()
          };

          // First pass: collect all unique values and count bookings
          bookings.forEach(booking => {
            // Handle agents with counts
            if (booking.agent) {
              const agent = booking.agent.trim();
              agentCounts[agent] = (agentCounts[agent] || 0) + 1;
              uniqueMetadata.agents.set(agent, {
                agent: agent,
                type: booking.type?.trim() || '',
                option: booking.option?.trim() || '',
                location: booking.location?.trim() || '',
                bookingCount: agentCounts[agent]
              });
            }

            // Collect all unique types, options, and locations
            if (booking.type) uniqueMetadata.types.add(booking.type.trim());
            if (booking.option) uniqueMetadata.options.add(booking.option.trim());
            if (booking.location) uniqueMetadata.locations.add(booking.location.trim());
          });

          // Transform to expected format
          result = {
            data: {
              bookingMetadatas: Array.from(uniqueMetadata.agents.values())
                .sort((a, b) => a.agent.localeCompare(b.agent))
                .map(agent => ({
                  ...agent,
                  types: Array.from(uniqueMetadata.types),
                  options: Array.from(uniqueMetadata.options),
                  locations: Array.from(uniqueMetadata.locations)
                }))
            }
          };
          break;
        }
        default:
          throw new Error(`Unknown query: ${queryName}`);
      }

      console.log(`Query ${queryName} result:`, result);
      return result;
    } catch (err) {
      console.error(`Query failed: ${queryName}`, err);
      throw err;
    }
  };

  return {
    dataConnect,
    loading,
    error,
    executeQuery,
    ready: !loading && !error && dataConnect !== null
  };
}; 