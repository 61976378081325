export const cruiseData = {
  'Aida Cruises': {
    name: 'Aida Cruises',
    tours: [
      'Aloe, Fort Nassau & Sightseeing',
      'Amphibious Land & Sea Adventure',
      'Beach hop & Turtle snorkel adventure',
      'Caves & City Center',
      'City Beach & Flamingo\'s',
      'Curacao Highlights',
      'Curacao Combo Drive',
      'Curacao Country Drive',
      'Historical Willemstad Trolley Train Tour',
      'Off road E-bike tour',
      'Panoramic Curacao',
      'See & Sea Island Tour',
      'Snorkeling Adventure with grilled lunch & open bar',
      'Walking tour incl. Maritime Museum',
      'Willemstad Walking tour'
    ],
  },
  'Atlas Ocean Voyages': {
    name: 'Atlas Ocean Voyages',
    tours: [
      'Capital Highlights by E-bike with swim stop at Mambo',
      'Discover Curacao Island',
      'Foodhopping tour',
      'Off road adventure in the wild west',
      'Seaworld Explorer'
    ],
  },
  'Ambassador Cruise Line': {
    name: 'Ambassador Cruise Line',
    tours: [
      'Curacao @Ease',
      'Curacao\'s Best of the West',
      'Discover Curacao',
      'Sea & See Curacao',
      'Willemstad on Foot',
      'Willemstad Trolley Train'
    ],
  },
  'Azamara': {
    name: 'Azamara',
    tours: [
      'Beach hop & turtle snorkel adventure',
      'Beauties of Curacao',
      'Capital Highlights by E-bike and Mangrove Park',
      'Curacao Hightlights',
      'Curacao snorkel cruise and sightseeing',
      'Hato Caves & Walk through Willemstad',
      'Historical Willemstad Trolley Train Tour',
      'Made in Curacao',
      'Sea & See Island Discovery',
    ],
  },
  'Carnival Cruise Line': {
    name: 'Carnival Cruise Line',
    tours: [
      'All inclusive beach day at a resort',
      'Amazonia the jungle experience & ostrich farm',
      'Amphibious Land & Sea Adventure',
      'Best of the west',
      'City Sights, Flamingo\'s & Beach',
      'Coral Reef Semi Submarine Adventure',
      'Discover Curacao Island Tour',
      'Electric Bike, Rif Mangrove & Chill Beach Bar',
      'Full day tour of Curacao',
      'Hato Caves, Flamingo\'s & Nature Hike',
      'Hato Caves & Willemstad City Tour',
      'Must See of Curacao & guided shopping tour by foot',
      'Off road adventure in the west',
      'Off road dune buggy adventure & Beach',
      'Ostrich Farm, Art Factory & Sightseeing',
      'Pirate Bay Beach & Snorkel',
      'Sea & See Curacao',
      'Snorkeling adventure with grilled lunch & open bar',
      'Turtle snorkel & cas abao beach',
      'Willemstad Pub Crawl',
      'Willemstad walking tour - small group',
      'Willemstad Trolley train Tour'
    ],
  },
  'Costa': {
    name: 'Costa',
    tours: [
      'Caves & City Center',
      'Coral Reef tour',
      'Curacao Country Drive',
      'Curacao Highlights',
      'Discover Curacao Island Tour',
      'Historical Willemstad Trolley Train Tour',
      'See & Sea Island Tour',
      'Views of the Country and Beach Break',
    ],
  },
  'Club Med Marine': {
    name: 'Club Med Marine',
    tours: [
      'Aloe, Artwork & Amazonia',
      'Jewish Heritage Tour',
      'Off road dune buggy Adventure & Beach',
      'The colors of Curacao (trolley)',
      'Walking tour, inclusief ABC Rum Distillery',
    ],
  },
  'Disney Cruise Line': {
    name: 'Disney Cruise Line',
    tours: [
      'Coastal & Country Drive',
      'Discover Curacao Island Tour',
      'Downtown walking tour inclusief maritime museum',
      'Fascinating Curacao & Folkloric Show',
      'Fun Beach Express, Sightseeing & Snorkel',
      'Ostrich Farm & Aloe Farm',
      'Ostrich Farm & Sightseeing',
      'Sea & See Island Tour',
      'Seaworld Explorer',
      'Spanish Water Snorkel Tour',
      'Trolley train',
    ],
  },
  'Emerald Cruises': {
    name: 'Emerald Cruises',
    tours: [
      'Capital Highlights by E-Bike',
      'Treasure of Curacao',
    ],
  },
  'Explora Journeys': {
    name: 'Explora Journeys',
    tours: [
      'Art Experience at landhuis Bloemhof',
      'Ancient Mango Forest & Dutch Manor House Picnic',
      'Cathedral of Thorns under moon glow',
      'Cliffs and Caves of Hato Plains with an Easy E-Bike',
      'Curacao Cooking Class with a Caribbean Chef',
      'Explore the Art, Culture and Cuisine of Colourful Willemstad',
      'Forest Hike & Snorkel Dive',
      'Progressive Dinner of Curacao Cuisine',
      'Raise a Glass to the Flavours of Curacao\'s Local Spirits',
      'Sail into Curacao\'s Orange Sunset',
      'Snorkeling Caerulean Curacao\'s of two beautiful beaches',
      'Snorkeling Coral-Crusted Wrecks',
    ],
  },
  'Hapag-Lloyd Cruises': {
    name: 'Hapag-Lloyd Cruises',
    tours: [
      'Amphibious Land & Sea Adventure',
      'Cas Abao Beach Break & Scenic Drive',
      'Caves Meets Music History',
      'Discover Curacao',
      'Explore Maritime Museum & Willemstad',
      'Kaya Kaya Walking & Mangrove Kayaking',
      'West Coast Off-Road Adventure',
      'Willemstad walking tour',
    ],
  },
  'Holland America Line': {
    name: 'Holland America Line',
    tours: [
      'Authentically Curacao',
      'Caribbean Cook Workshop',
      'Caves & City Highlights',
      'Curacao Beach Express',
      'Curacao Country Drive',
      'Curacao Food Hopping & Island Views',
      'Curacao Land & Sea',
      'Discover Curacao',
      'Fascinating Curacao & Folkloric Show',
      'Made in Curacao',
      'Mini 4x4 Adventure and Beach Break',
      'Ostrich Farm & Aloe Plantation',
      'Willemstad Trolley Train'
    ],
  },
  'Marella Cruises': {
    name: 'Marella Cruises',
    tours: [
      'Coastal & Country Drive',
      'Discover Curacao Island Tour',
      'Sea & See Island Tour',
      'Turtle Discovery & Beach',
    ],
  },
  'MSC Cruises': {
    name: 'MSC Cruises',
    tours: [
      'Beach Express',
      'Caves & City Center',
      'Country Drive',
      'Curacao Highlights & Aquarium',
      'Discover Curacao',
      'Downtown walking tour & maritime museum',
      'Panoramic Curacao',
      'Rainforest Mystery & Sightseeing',
      'Taste of Curacao',
      'Trolley Train',
      'Sea & See',
      'Seaworld Explorer',
      'Willemstad E-Bike and Curacao Museum',
    ],
  },
  'Noble Caledonia': {
    name: 'Noble Caledonia',
    tours: [
      'Curacao Country Drive',
      'Willemstad walking tour',
    ],
  },
  'Norwegian Cruise Line': {
    name: 'Norwegian Cruise Line',
    tours: [
      'Discover Curacao Island Tour',
      'Fun Beach Express',
      'Hato Caves & City Center',
      'Hato Caves & Ostrich Farm Adventure',
      'Sea & See Island Tour',
      'Willemstad Trolley Train',
    ],
  },
  'Oceania Cruises': {
    name: 'Oceania Cruises',
    tours: [
      'Beach hop & Turtle snorkel Adventure',
      'Curacao Highlights',
      'Curacao Country Drive',
      'Discover Curacao\'s Beautiful Beaches',
      'Sea & See Island Discovery',
      'Seaworld Explorer Semi-Sub',
      'Trolley Train',
    ],
  },
  'P&O Cruises': {
    name: 'P&O Cruises',
    tours: [
      'Beach by Chiva Bus',
      'Best of the west',
      'Cocktail Workshop',
      'Island Tour & Hato Caves',
      'Off road adventure in the west',
      'Off road E-Bike Tour',
      'Seaworld Explorer',
      'Semi Sub & Sights',
      'Willemstad Trolley Train',
    ],
  },
  'Phoenix Reisen': {
    name: 'Phoenix Reisen',
    tours: [
      'Best of the west',
      'Caves & City Center',
      'Discover Curacao Island Tour',
      'Kaya Kaya Walking & Mangrove Kayaking',
      'Made in Curacao',
      'Panoramic Curacao',
      'Amphibious Land & Sea Adventure',
      'Willemstad Trolley Train',
    ],
  },
  'Ponant': {
    name: 'Ponant',
    tours: [
      'Art Experience at Landhuis Bloemhof',
      'Curacao Nature Diversity',
      'Indigo workshop at San Nicolas',
      'Willemstad walking tour inclusief kura hulanda',
    ],
  },
  'Princess Cruises': {
    name: 'Princess Cruises',
    tours: [
      'Best of Curacao: Hato Caves, Willemstad & Curacao Museum',
      'Capital highlights by Electric bike',
      'Curacao Beach Tropical Express',
      'Hato Caves & Willemstad walking tour',
      'Hato Caves, Willemstad, Museum, Ship lunch break & scenic drive',
      'Made in Curacao',
      'Mini 4x4 Adventure & Beach Break',
      'National Park, Museum & Scenic Country Drive',
      'Ostrich Farm, Art Workshop & Scenic Drive',
      'West side scenic drive and beach break',
      'Scenic drive, distillery & Aloe Vera Farm',
      'Seaworld Explorer Semi-Sub & scenic drive',
      'Sunken Tugboat & Underwater Marine Park Snorkel',
      'Willemstad Trolley Train Historic Tour',
    ],
  },
  'Regent Seven Seas Cruises': {
    name: 'Regent Seven Seas Cruises',
    tours: [
      'Beach hop & Turtle snorkel Adventure',
      'Curacao Highlights',
      'Discover Curacao\'s Beautiful Beaches',
      'Sea & See Island Tour',
      'Seaworld Explorer Semi-Submarine',
      'Scenic Country Drive',
      'Trolley Train through history',
    ],
  },
  'Royal Caribbean International': {
    name: 'Royal Caribbean International',
    tours: [
      'Beach hop & turtle snorkel',
      'Beauties of Curacao',
      'Cas Abao Beach Break & Scenic Drive',
      'Caves & City Center',
      'Curacao Cultural Heritage',
      'Curacao Evening Pub Crawl',
      'Discover Curacao',
      'Evening Food Hopping tour',
      'Fascinating Curacao',
      'Fun Beach Express, Sightseeing & snorkel',
      'Natural Products of Curacao',
      'Panoramic Curacao Island Tour',
      'Sea & See Island Tour',
      'Seaworld Explorer',
      'Snorkel the treasures of Caracasbay',
      'Caracas Bay Cruise',
      'West Coast Off-Road Adventure',
      'Willemstad Trolley Train',
    ],
  },
  'Saga Cruises': {
    name: 'Saga Cruises',
    tours: [
      'Beach hop and turtle snorkel',
      'Curacao Coast and Country side',
      'Curacao Island Drive',
      'Curacao Island Highlights',
      'Willemstad and Hato Caves',
      'Willemstad Trolley Train & Fort Amsterdam',
    ],
  },
  'Seabourn Cruise Line': {
    name: 'Seabourn Cruise Line',
    tours: [
      'Capital Hightlights by Electric Bike',
      'Curacao See & Sea',
      'Hato Caves & Willemstad Sights',
      'Scenic Sunset Cruise by Yacht',
    ],
  },
  'Silversea Cruises': {
    name: 'Silversea Cruises',
    tours: [
      'Beach hop & turtle snorkel adventure',
      'Capital Hightlights by Electric Bike',
      'Discover Curacao',
      'Made in Curacao',
      'Off Road adventure in the wild west',
      'Sea & See Island Tour',
      'Seaworld Explorer',
      'Sunset Catamaran Cruise',
      'Willemstad Trolley Train',
    ],
  },
  'Star Clippers': {
    name: 'Star Clippers',
    tours: [
      'Country Drive Tour',
      'Beach hop & turtle snorkel adventure',
      'Seaworld Explorer',
    ],
  },
  'TUI Cruises': {
    name: 'TUI Cruises',
    tours: [
      'Beach & snorkel adventure',
      'Beach hop & turtle snorkel adventure',
      'Curacao Country Drive',
      'Curacao Highlights (evening tour)',
      'Curacao Highlights',
      'Discover Beaches of Curacao',
      'Discover Curacao & Hato Caves',
      'Downtown walking tour',
      'Historical Willemstad by trolley train',
      'Knip Beach & Flamingos',
      'Panoramic Curacao',
      'Sea & Sea Island Tour',
      'Willemstad walking tour & beach',
    ],
  },
  'Victoria Cruise Line': {
    name: 'Victoria Cruise Line',
    tours: [
      'Beach hop & turtle snorkel adventure',
      'Caves Meets Music History',
      'Cooking with the Caribbean Chef',
      'Discover Curacao',
      'Natural wonders of Curacao',
      'Willemstad walking tour inclusief Kura Hulanda',
    ],
  },
  'Virgin Voyages': {
    name: 'Virgin Voyages',
    tours: [
      'Beach Hop & Snorkel Stops',
      'Cave Mazes & Market Places',
      'Curacao Cultural Cocktail Caper',
      'Half-Day the Beach Resort Way',
      'Keepin\' it Curacao - Views & Beach Break',
      'Made in Curacao - Aloe & Artisans',
      'Natural Phenomena in a Military Truck',
      'The Curacao Trinity',
      'The (street) art of travel in Willemstad',
      'Wild Trails & Hidden finds E-Bike Circuit',
      'Willemstad by trolley',
      'Willemstad Walkabout',
    ],
  },
  'Local Tours': {
    name: 'Local Tours',
    tours: [
      'Local Tours 1 - 3 uur',
      'Local Tours 3 - 5 uur',
      'Local Tours 5 - 8 uur',
    ],
  },
  'Sandals': {
    name: 'Sandals',
    tours: [
      'Culinary Vibez',
      'Curacao Chichi Sculpture Painting Experience',
      'Curacao Off Road Explorer',
      'Historic City Walk - Punda & Otrobanda',
      'Punda Vibes',
      'Sandals Exclusive: Curacao Sightseeing Tour',
      'Willemstad E-Bike City Tour',
    ],
  },
  'Stay over Tours': {
    name: 'Stay over Tours',
    tours: [
      'Beach Escape',
      'Curacao Island Tour 1-talig',
      'Curacao Island Tour 2-talig',
      'Half day snorkel trip by boat',
      'Historic Walking Tour',
      'Off Road Truck Tour West',
      'Seaworld Explorer',
      'Sunset Cruise - Spanish Water Sunset Trip by Motor Catamaran',
      'Trolley Train City Center in Curacao',
    ],
  },
  
};