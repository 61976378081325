import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Card, Typography, Box, Divider, Grid, CircularProgress, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button } from '@mui/material';
import { collection, getDocs, query, orderBy, addDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 600,
  minHeight: 600,
  boxShadow: '0 12px 40px rgba(0,0,0,0.2)',
  borderRadius: '16px',
  overflow: 'hidden',
  position: 'relative',
  margin: 'auto',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url("/images/Overlay.svg")',
    backgroundSize: 'cover',
    opacity: 0.15,
    zIndex: 0,
  }
}));

const LogoBox = styled(Box)({
  position: 'absolute',
  top: '20px',
  left: '20px',
  width: '80px',
  height: '80px',
  backgroundImage: 'url("/images/Curacao_Tours_logo.png")',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  zIndex: 2,
});

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  padding: theme.spacing(4),
  paddingTop: theme.spacing(12),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  height: '100%',
  overflowY: 'auto',
}));

const MenuTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.main,
  textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
  fontSize: '2rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.5rem',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: '100%',
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}));
function GuestMenu() {
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [numGuests, setNumGuests] = useState(1);
  const [tourDate, setTourDate] = useState('');
  const [bookingId, setBookingId] = useState('');
  const [guestChoices, setGuestChoices] = useState({});
  const [menuData, setMenuData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setBookingId(queryParams.get('bookingId') || '');
    setCustomerName(decodeURIComponent(queryParams.get('name') || ''));
    setCustomerEmail(decodeURIComponent(queryParams.get('email') || '').replace('%40', '@'));
    setNumGuests(parseInt(queryParams.get('guests') || '1', 10));
    setTourDate(queryParams.get('date') || '');
  }, []);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const menusQuery = query(collection(db, 'menus'), orderBy('createdAt', 'desc'));
        const menusSnapshot = await getDocs(menusQuery);
        const menusList = menusSnapshot.docs.map(doc => doc.data());

        const processedData = menusList.reduce((acc, menu) => {
          if (!acc[menu.restaurantName]) {
            acc[menu.restaurantName] = {};
          }
          if (!acc[menu.restaurantName][menu.category]) {
            acc[menu.restaurantName][menu.category] = [];
          }
          acc[menu.restaurantName][menu.category] = menu.menuItems;
          return acc;
        }, {});

        setMenuData(processedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching menu data: ', error);
        setLoading(false);
      }
    };

    fetchMenuData();
  }, []);

  useEffect(() => {
    const initializeGuestChoices = () => {
      const choices = {};
      for (let i = 1; i <= numGuests; i++) {
        choices[`Guest ${i}`] = {};
      }
      setGuestChoices(choices);
    };

    initializeGuestChoices();
  }, [numGuests]);

  const handleGuestChoice = (guestNumber, restaurant, category, option) => {
    setGuestChoices(prev => ({
      ...prev,
      [guestNumber]: {
        ...prev[guestNumber],
        [restaurant]: {
          ...prev[guestNumber][restaurant],
          [category]: option
        }
      }
    }));
  };

  const handleSubmitChoices = async () => {
    try {
      const choicesData = {
        bookingId,
        numGuests,
        tourDate,
        customerName,
        customerEmail,
        guestChoices,
        submittedAt: new Date()
      };
      
      await addDoc(collection(db, 'guestChoices'), choicesData);
      alert('Choices submitted successfully!');
    } catch (error) {
      console.error('Error submitting choices: ', error);
      alert('Error submitting choices. Please try again.');
    }
  };

  if (loading) {
    return <CircularProgress size={60} />;
  }

  return (
    <StyledCard>
      <LogoBox />
      <ContentBox>
        <MenuTitle variant="h4" component="div">
          Guest Menu Choices
        </MenuTitle>
        
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Booking Details</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography><strong>Customer:</strong> {customerName}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>Email:</strong> {customerEmail}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>Tour Date:</strong> {tourDate}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>Number of Guests:</strong> {numGuests}</Typography>
            </Grid>
          </Grid>
        </Box>
        
        <Divider sx={{ my: 2 }} />
        
        {Object.keys(guestChoices).map((guestNumber) => (
          <Box key={guestNumber} sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: 'text.secondary' }}>
              {guestNumber}
            </Typography>
            {Object.entries(menuData).map(([restaurant, categories]) => (
              <Box key={restaurant} sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>{restaurant}</Typography>
                {Object.entries(categories).map(([category, items]) => (
                  <StyledFormControl key={category}>
                    <StyledFormLabel>{category}</StyledFormLabel>
                    <RadioGroup
                      value={guestChoices[guestNumber][restaurant]?.[category] || ''}
                      onChange={(e) => handleGuestChoice(guestNumber, restaurant, category, e.target.value)}
                    >
                      {items.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          value={item.name}
                          control={<Radio />}
                          label={item.name}
                        />
                      ))}
                    </RadioGroup>
                  </StyledFormControl>
                ))}
              </Box>
            ))}
            <Divider sx={{ my: 2 }} />
          </Box>
        ))}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 4 }}
          onClick={handleSubmitChoices}
        >
          Submit All Choices
        </Button>
      </ContentBox>
    </StyledCard>
  );
}

export default GuestMenu;