// WeatherWidget.js
import React, { useState, useEffect } from 'react';

const WeatherWidget = () => {
  const [weather, setWeather] = useState(null);

  useEffect(() => {
    // Use your actual latitude and longitude for Curacao
    const lat = '12.1696'; // Curacao latitude
    const lon = '-68.9900'; // Curacao longitude
    const apiKey = '7fd4e249b02dcc77a0f96c3f7cbcd825';
    const url = `https://api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${lon}&exclude=minutely,hourly,daily,alerts&appid=${apiKey}&units=metric`;

    const fetchWeather = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setWeather(data);
      } catch (error) {
        console.error("Could not fetch weather data", error);
      }
    };

    const intervalId = setInterval(fetchWeather, 3 * 60 * 60 * 1000); // Refresh every 3 hours

    fetchWeather(); // Initial fetch

    // Clear the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  if (!weather) return <div>Loading...</div>;

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#FDD835', // Yellow background color
      color: '#1565C0', // Blue color for text
      fontFamily: 'Arial, sans-serif', // Make sure you use the font that matches your design
      padding: '10px 20px', // Increased padding
      borderRadius: '10px',
      fontSize: '14px' // Smaller font size
    }}>
      <p style={{ fontSize: '14px', fontWeight: 'bold', margin: '0 5px 0 0' }}>CURAÇAO</p>
      <p style={{ fontSize: '14px', margin: '0 5px 0 0', fontWeight: 'bold' }}>{Math.round(weather.current.temp)}°</p>
      <img src={`http://openweathermap.org/img/w/${weather.current.weather[0].icon}.png`} alt="Weather icon" style={{ width: '20px', height: '20px' }} />
    </div>
  );
};

export default WeatherWidget;