import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend
} from 'chart.js';
import './dashboard.css'; // Ensure this path is correct

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const NumberOfPassengersCorendon = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    // Define an array of distinct colors
    const barColors = [
        'rgba(255, 99, 132, 0.6)',   // Red
        'rgba(54, 162, 235, 0.6)',   // Blue
        'rgba(255, 206, 86, 0.6)',   // Yellow
        'rgba(75, 192, 192, 0.6)',   // Teal
        'rgba(153, 102, 255, 0.6)',  // Purple
        'rgba(255, 159, 64, 0.6)',   // Orange
        'rgba(199, 199, 199, 0.6)',  // Gray
        'rgba(83, 102, 255, 0.6)',   // Indigo
        'rgba(255, 99, 255, 0.6)',   // Pink
        'rgba(159, 255, 64, 0.6)'    // Lime
    ];

    const fetchData = async () => {
        console.log('Starting data fetch process');

        const fetch2024Data = async () => {
            console.log('Fetching 2024 data');
            const range = `SardonyxFull!A2:N`; // Columns A to N (14 columns)
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();
                console.log(`Received 2024 data: ${data.length} rows`);

                let optionsData = {};
                let processedRows = 0;
                let skippedRows = 0;

                data.forEach((row, index) => {
                    if (!row || row.length < 14) {
                        console.log(`Skipping row ${index + 2}: Insufficient data (length: ${row ? row.length : 0})`);
                        skippedRows++;
                        return;
                    }
                    const [day, month, year] = row[0].split('/').map(Number);
                    const date = new Date(year, month - 1, day);

                    if (date.getFullYear() !== 2024) {
                        console.log(`Skipping row ${index + 2}: Not from 2024 (Date: ${row[0]})`);
                        skippedRows++;
                    } else if (row[1].trim() !== "Corendon International Travel") {
                        console.log(`Skipping row ${index + 2}: Not Corendon International Travel (Company: "${row[1]}")`);
                        skippedRows++;
                    } else if (row[10].trim() !== "GS-ARR :: Handling Fee") {
                        console.log(`Skipping row ${index + 2}: Not GS-ARR :: Handling Fee (TransType: "${row[10]}")`);
                        skippedRows++;
                    } else {
                        const passengers = parseInt(row[7], 10) || 0;
                        const option = row[13] || 'Unknown'; // Column N
                        if (!optionsData[option]) {
                            optionsData[option] = Array(12).fill(0);
                        }
                        optionsData[option][month - 1] += passengers;
                        processedRows++;
                    }
                });

                console.log(`2024 data processing complete. Processed rows: ${processedRows}, Skipped rows: ${skippedRows}`);
                console.log('2024 Options data:', optionsData);

                return optionsData;
            } catch (error) {
                console.error(`Error fetching 2024 data:`, error);
                return {};
            }
        };

        const fetch2023Data = async () => {
            console.log('Fetching 2023 data');
            const range = `Sardonyx2023!A2:I`;
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();
                console.log(`Received 2023 data: ${data.length} rows`);

                let monthlyData = Array(12).fill(0);
                let processedRows = 0;
                let skippedRows = 0;

                data.forEach((row, index) => {
                    if (!row || row.length < 3) {
                        console.log(`Skipping row ${index + 2}: Insufficient data (length: ${row ? row.length : 0})`);
                        skippedRows++;
                        return;
                    }
                    const [month, day, year] = row[0].split('/').map(Number);
                    const date = new Date(year, month - 1, day);

                    if (date.getFullYear() !== 2023) {
                        console.log(`Skipping row ${index + 2}: Not from 2023 (Date: ${row[0]})`);
                        skippedRows++;
                    } else if (row[1].trim() !== "Air Canada Vacations") {
                        console.log(`Skipping row ${index + 2}: Not Corendon International Travel (Company: "${row[1]}")`);
                        skippedRows++;
                    } else {
                        const passengers = parseInt(row[2], 10) || 0;
                        monthlyData[month - 1] += passengers;
                        processedRows++;
                    }
                });

                console.log(`2023 data processing complete. Processed rows: ${processedRows}, Skipped rows: ${skippedRows}`);
                console.log('2023 Monthly data:', monthlyData);

                return monthlyData;
            } catch (error) {
                console.error(`Error fetching 2023 data:`, error);
                return Array(12).fill(0);
            }
        };

        const data2024 = await fetch2024Data();
        const data2023 = await fetch2023Data();

        const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // Calculate the total for all options in 2024
        const total2024 = Array(12).fill(0);
        Object.values(data2024).forEach(optionData => {
            optionData.forEach((value, index) => {
                total2024[index] += value;
            });
        });

        const datasets = Object.entries(data2024).map(([option, data], index) => ({
            type: 'bar',
            label: `2024 ${option}`,
            data: data,
            backgroundColor: barColors[index % barColors.length],
            borderColor: barColors[index % barColors.length].replace('0.6', '1'),
            borderWidth: 1
        }));

        // Add the 2024 total dataset
        datasets.unshift({
            type: 'bar',
            label: '2024 Total',
            data: total2024,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            borderColor: 'rgba(0, 0, 0, 1)',
            borderWidth: 1
        });

        // Add the 2023 line chart
        datasets.push({
            type: 'line',
            label: '2023 Total Transfers',
            data: data2023,
            borderColor: 'rgba(0, 0, 0, 1)',  // Black line for 2023 data
            borderWidth: 2,
            fill: false,
            tension: 0.1
        });

        setChartData({
            labels: labels,
            datasets: datasets
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const refreshData = () => {
        fetchData();
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#333',
                },
            },
            y: {
                grid: {
                    color: '#eaeaea',
                },
                ticks: {
                    color: '#333',
                    beginAtZero: true,
                },
            },
        },
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
        },
    };

    return (
        <div className="chart-container">
            <h2 className="chart-title">Corendon Passengers: 2024 vs 2023 Total</h2>
            <button onClick={refreshData}>Refresh Data</button>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default NumberOfPassengersCorendon;
