export const cleanupClarity = () => {
  // Remove any Clarity-related scripts
  const scripts = document.getElementsByTagName('script');
  for (let i = scripts.length - 1; i >= 0; i--) {
    const script = scripts[i];
    if (script.src && script.src.includes('clarity')) {
      script.parentNode.removeChild(script);
    }
  }

  // Clean up any Clarity objects
  if (window.clarity) {
    window.clarity = {
      disabled: true,
      q: [],
      push: function() {}
    };
  }
}; 