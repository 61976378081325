import React, { createContext, useContext } from 'react';
import { useDataConnect as useDataConnectHook } from '../hooks/useDataConnect';

const DataConnectContext = createContext(null);

export const DataConnectProvider = ({ children }) => {
  const dataConnectValue = useDataConnectHook();

  return (
    <DataConnectContext.Provider value={dataConnectValue}>
      {children}
    </DataConnectContext.Provider>
  );
};

export const useDataConnect = () => {
  const context = useContext(DataConnectContext);
  if (!context) {
    throw new Error('useDataConnect must be used within a DataConnectProvider');
  }
  return context;
}; 