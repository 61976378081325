import React from 'react';
import { Card, CardActionArea, CardContent, Typography, Grid, Container, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import ReviewIcon from '@mui/icons-material/RateReview';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginIcon from '@mui/icons-material/Login';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import { blue, green, orange, red, purple } from '@mui/material/colors';

function AdminPage() {
  const links = [
    { path: '/fbtt', label: 'FBTT Review Form', Icon: ReviewIcon, color: blue[500] },
    { path: '/activity-manager', label: 'Activity Manager', Icon: SettingsIcon, color: green[500] },
    { path: '/dashboard', label: 'Dashboard', Icon: DashboardIcon, color: orange[500] },
    { path: '/reviews', label: 'Reviews', Icon: ReviewIcon, color: red[500] },
    { path: '/menu-editor', label: 'Menu Editor', Icon: RestaurantMenuIcon, color: purple[500] },
    { path: 'https://login.trekksoft.com/en/', label: 'Trekksoft Login', Icon: LoginIcon, color: blue[700], isExternal: true },
  ];

  return (
    <Container maxWidth="md" sx={{ mt: 15, mb: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom sx={{ textAlign: 'center', mb: 5, fontWeight: 'bold', color: 'primary.main' }}>
        Admin Dashboard
      </Typography>
      <Grid container spacing={4}>
        {links.map((link, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ boxShadow: 3, '&:hover': { boxShadow: 8 } }}>
              {link.isExternal ? (
                <CardActionArea sx={{ '.MuiCardActionArea-focusHighlight': { bgcolor: link.color } }}>
                  <a href={link.path} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <CardMedia>
                      <link.Icon sx={{ fontSize: 60, color: link.color, p: 2, display: 'flex', justifyContent: 'center' }} />
                    </CardMedia>
                    <CardContent sx={{ bgcolor: link.color, color: '#fff' }}>
                      <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                        {link.label}
                      </Typography>
                    </CardContent>
                  </a>
                </CardActionArea>
              ) : (
                <CardActionArea component={Link} to={link.path} sx={{ '.MuiCardActionArea-focusHighlight': { bgcolor: link.color } }}>
                  <CardMedia>
                    <link.Icon sx={{ fontSize: 60, color: link.color, p: 2, display: 'flex', justifyContent: 'center' }} />
                  </CardMedia>
                  <CardContent sx={{ bgcolor: link.color, color: '#fff' }}>
                    <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                      {link.label}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default AdminPage;