import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import './dashboard.css'; // Make sure this path is correct

const CurrentMonthSardonyxPax = () => {
    const [currentMonthPax, setCurrentMonthPax] = useState(0); // State to store current month Pax
    const [ytdAmount, setYtdAmount] = useState(0);
    const [previousYearPax, setPreviousYearPax] = useState(0); // State to store previous year Pax
    const [previousYearYtdAmount, setPreviousYearYtdAmount] = useState(0); // State to store previous year YTD amount
    const [previousYearMonthPax, setPreviousYearMonthPax] = useState(0); // State to store previous year current month Pax
    const [isLoading, setIsLoading] = useState(false);

    const fetchPaxData = async () => {
        setIsLoading(true);
        const currentRange = `Sardonyx!A2:J`;
        const previousRange = `Sardonyx2023!A2:J`;
        const currentFunctionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(currentRange)}`;
        const previousFunctionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(previousRange)}`;

        try {
            const [currentResponse, previousResponse] = await Promise.all([
                fetch(currentFunctionUrl),
                fetch(previousFunctionUrl)
            ]);

            if (!currentResponse.ok || !previousResponse.ok) throw new Error('Failed to fetch');

            const currentData = await currentResponse.json();
            const previousData = await previousResponse.json();

            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const previousYear = currentYear - 1;

            let yearToDateAmount = 0;
            let currentMonthPaxCount = 0;
            let prevYearYtdAmount = 0;
            let prevYearMonthPax = 0;

            const processData = (data, isCurrentYear) => {
                const uniqueReservations = new Set();
                data.forEach(row => {
                    const [day, month, year] = row[0].split('/').map(Number);
                    const date = new Date(year, month - 1, day);
                    const pax = parseInt(row[2], 10);
                    const reservationNo = row[9];

                    if (!isNaN(pax) && !uniqueReservations.has(reservationNo)) {
                        uniqueReservations.add(reservationNo);
                        if (isCurrentYear) {
                            if (date <= currentDate) {
                                yearToDateAmount += pax;
                                if (date.getMonth() === currentMonth && date.getFullYear() === currentYear) {
                                    currentMonthPaxCount += pax;
                                }
                            }
                        } else {
                            if (date.getFullYear() === previousYear) {
                                if (date.getMonth() <= currentMonth) {
                                    prevYearYtdAmount += pax;
                                    if (date.getMonth() === currentMonth) {
                                        prevYearMonthPax += pax;
                                    }
                                }
                            }
                        }
                    }
                });
            };

            processData(currentData, true);
            processData(previousData, false);

            setYtdAmount(yearToDateAmount);
            setCurrentMonthPax(currentMonthPaxCount);
            setPreviousYearYtdAmount(prevYearYtdAmount);
            setPreviousYearMonthPax(prevYearMonthPax);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPaxData();
    }, []);

    const handleRefresh = () => {
        fetchPaxData();
    };

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const currentMonthName = monthNames[new Date().getMonth()];
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    return (
        <Card className="sardonyx-card">
            <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                        Sardonyx Pax Comparison
                    </Typography>
                    <IconButton onClick={handleRefresh} disabled={isLoading}>
                        <RefreshIcon />
                    </IconButton>
                </div>
                
                <Typography variant="subtitle1" style={{ marginTop: '16px', fontWeight: 'bold' }}>
                    Current Year ({currentYear})
                </Typography>
                <Typography variant="body1">
                    {currentMonthName} Pax: {currentMonthPax || 'No data'}
                </Typography>
                <Typography variant="body1">
                    YTD Total: {ytdAmount}
                </Typography>
                
                <Typography variant="subtitle1" style={{ marginTop: '16px', fontWeight: 'bold' }}>
                    Previous Year ({previousYear})
                </Typography>
                <Typography variant="body1">
                    {currentMonthName} Pax: {previousYearMonthPax || 'No data'}
                </Typography>
                <Typography variant="body1">
                    YTD Total: {previousYearYtdAmount}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CurrentMonthSardonyxPax;