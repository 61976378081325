// src/hooks/TrekksoftBookingWidget.js
import React, { useEffect } from 'react';

const TrekksoftBookingWidget = ({ tourId }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://bw.trekksoft.com/loader.js";
    script.async = true;
    script.dataset.owner = "trekksoft";
    script.dataset.type = "activity-booking";
    script.dataset.id = tourId;
    script.dataset.clientId = "BOOKIE-5badffc8663be9c7621fe18cf";
    script.dataset.token = "f644f78038045737f3c2da77ea17bf4521b12a1ccdc0aded";
    script.dataset.element = "embed";
    script.dataset.width = "600px";
    script.dataset.height = "750px";
    script.dataset.forceHeight = "true"; // Set force-height to true

    // Append the script to the div with id "trekksoft-booking-widget"
    const widgetDiv = document.getElementById('trekksoft-booking-widget');
    if (widgetDiv) {
      widgetDiv.appendChild(script);
    }

    return () => {
      // When the component unmounts, remove the script from the div
      if (widgetDiv) {
        widgetDiv.removeChild(script);
      }
    };
  }, [tourId]);

  // Set an explicit width and height on the div that matches the script attributes
  return <div id="trekksoft-booking-widget" style={{ width: '300px', height: '750px' }}></div>;
};

export default TrekksoftBookingWidget;
