import React from 'react';
import { Card, Grid, CardContent, Typography, Box, Divider } from '@mui/material';
import CsvUploader from '../../config/fileupload/sardonyx';
import SQLUploader from '../../config/fileupload/SQLUploader';
import ErrorBoundary from '../../components/ErrorBoundary';

const DashboardPage = () => {
  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom component="div">
        Data Upload Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                Data Management
              </Typography>
              
              <Grid container spacing={3}>
                {/* Excel/CSV Uploader */}
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom sx={{ color: '#666' }}>
                    Excel/CSV Upload
                  </Typography>
                  <CsvUploader />
                </Grid>

                {/* Vertical Divider */}
                <Grid item xs={12} md={0.1}>
                  <Divider orientation="vertical" sx={{ height: '100%' }} />
                </Grid>

                {/* SQL Database Uploader */}
                <Grid item xs={12} md={5.9}>
                  <Typography variant="subtitle1" gutterBottom sx={{ color: '#666' }}>
                    Database Upload
                  </Typography>
                  <ErrorBoundary>
                    <SQLUploader />
                  </ErrorBoundary>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardPage;
