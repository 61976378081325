import React, { useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { Alert } from '@mui/material';
import { chartColors } from './colours';
import { startOfMonth, format } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale);

const MonthlyRevenueByCompany = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });
    const [currentDate, setCurrentDate] = useState(new Date());
    const chartRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=Bookings_2024!A2:G`;
            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                const companyData = {};

                data.forEach(row => {
                    const date = new Date(row[6]); // Column G (Date)
                    if (date <= currentDate) {
                        const company = row[2]; // Column C (Company)
                        const revenue = parseFloat(row[4]) || 0; // Changed to column E (Revenue)
                        const monthKey = format(startOfMonth(date), 'yyyy-MM-01');

                        if (!companyData[company]) {
                            companyData[company] = {};
                        }
                        if (!companyData[company][monthKey]) {
                            companyData[company][monthKey] = 0;
                        }
                        companyData[company][monthKey] += revenue; // Sum revenue instead of pax
                    }
                });

                const colorKeys = Object.keys(chartColors);
                const datasets = Object.entries(companyData).map(([company, months], index) => ({
                    label: company,
                    data: Object.entries(months).map(([month, revenue]) => ({ x: month, y: revenue })),
                    borderColor: chartColors[colorKeys[index % colorKeys.length]],
                    backgroundColor: chartColors[colorKeys[index % colorKeys.length]],
                    fill: false,
                }));

                const allMonths = [...new Set(datasets.flatMap(dataset => dataset.data.map(point => point.x)))].sort();

                setChartData({
                    labels: allMonths,
                    datasets,
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        // Cleanup function to destroy the chart
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [currentDate]);

    return (
        <div>
            <h2>Monthly Revenue by Sales Channel (2024)</h2>
            <Alert severity="info" style={{ marginBottom: '1rem' }}>
                This chart displays monthly aggregated revenue data by company for bookings in 2024, up to {format(currentDate, 'MMMM yyyy')}.
            </Alert>
            <Bar 
                ref={chartRef}
                data={chartData} 
                options={{ 
                    responsive: true, 
                    plugins: { 
                        legend: { position: 'top' },
                        tooltip: {
                            callbacks: {
                                title: (context) => format(new Date(context[0].parsed.x), 'MMMM yyyy'),
                                label: (context) => `${context.dataset.label}: $${context.parsed.y.toLocaleString()}`
                            }
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'month',
                                displayFormats: {
                                    month: 'MMM yyyy'
                                }
                            },
                            adapters: {
                                date: {
                                    locale: enUS,
                                },
                            },
                            title: {
                                display: true,
                                text: 'Month'
                            }
                        },
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Revenue ($)'
                            },
                            ticks: {
                                callback: (value) => `$${value.toLocaleString()}`
                            }
                        }
                    }
                }} 
            />
        </div>
    );
};

export default MonthlyRevenueByCompany;
