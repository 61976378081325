// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from 'firebase/auth';
import { getDataConnect, connectDataConnectEmulator } from 'firebase/data-connect';
import { connectorConfig } from '@firebasegen/default-connector';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: "curacao-tours.appspot.com",
  messagingSenderId: "65353935429",
  appId: "1:65353935429:web:8a9a3791bbd02cd202112c",
  measurementId: "G-Y2Z69MMKPW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);

// Initialize Data Connect with environment variables
const initializeDataConnect = async () => {
  const dc = await getDataConnect(app, {
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    location: process.env.REACT_APP_FIREBASE_LOCATION,
    service: process.env.REACT_APP_DATACONNECT_SERVICE,
    connector: connectorConfig.connector,
    databaseId: "sardonyx"
  });

  // Connect to emulator in development
  if (process.env.NODE_ENV === 'development') {
    connectDataConnectEmulator(dc, 'localhost', 9199);
  }

  return dc;
};

// Export initialized services
export { 
  app, 
  db, 
  functions, 
  analytics, 
  auth, 
  initializeDataConnect 
};
