import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BookingsPerDayBarChart = () => {
    const [days, setDays] = useState(7); // Default to last 7 days
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: `Total Bookings`,
            data: [],
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        }]
    });

    useEffect(() => {
        const fetchBookingsData = async () => {
            // This is where you'd fetch data from your backend. For demonstration, we're generating fake data.
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(endDate.getDate() - days + 1);

            let labels = [];
            let bookingsData = new Array(days).fill(0); // Initialize bookings data array

            // Generate labels and fake data for each day
            for (let i = 0; i < days; i++) {
                const currentDate = new Date(startDate);
                currentDate.setDate(startDate.getDate() + i);
                labels.push(currentDate.toISOString().split('T')[0]);
                
                // Simulate random bookings data. Replace with actual data fetching logic.
                bookingsData[i] = Math.floor(Math.random() * 100); // Random bookings count
            }

            setChartData({
                labels: labels,
                datasets: [{
                    label: `Total Bookings for the Last ${days} Days`,
                    data: bookingsData,
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                }]
            });
        };

        fetchBookingsData();
    }, [days]);

    const handleChange = (event) => {
        setDays(Number(event.target.value));
    };

    return (
        <div>
            <h2>Bookings Per Day</h2>
            <div>
                <label htmlFor="days-select">Select Range: </label>
                <select id="days-select" value={days} onChange={handleChange}>
                    <option value={7}>Last 7 Days</option>
                    <option value={30}>Last 30 Days</option>
                    <option value={90}>Last 90 Days</option>
                </select>
            </div>
            <Bar data={chartData} options={{ responsive: true, scales: { y: { beginAtZero: true } } }} />
        </div>
    );
};

export default BookingsPerDayBarChart;
