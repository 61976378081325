import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  List, 
  ListItem, 
  ListItemText,
  CircularProgress,
  Button,
  Alert
} from '@mui/material';
import { useDataConnect } from '../../hooks/useDataConnect';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { getApp } from 'firebase/app';

const MetadataSection = ({ title, items }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        {title} ({items.length})
      </Typography>
      <List dense sx={{ maxHeight: 400, overflow: 'auto' }}>
        {items.map((item, index) => (
          <ListItem key={index}>
            <ListItemText 
              primary={item.name || item}
              secondary={item.count ? `${item.count.toLocaleString()} bookings` : null}
            />
          </ListItem>
        ))}
      </List>
    </CardContent>
  </Card>
);

const MetadataDisplay = () => {
  const [metadata, setMetadata] = useState({
    agents: [],
    types: [],
    options: [],
    locations: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const { executeQuery, ready } = useDataConnect();
  const db = getFirestore(getApp());

  useEffect(() => {
    const initializeMetadata = async () => {
      console.log('🔄 Initializing metadata...', { ready });
      if (!ready) {
        console.log('⏳ Waiting for DataConnect to be ready');
        return;
      }
      
      console.log('🔄 Fetching fresh metadata...');
      await fetchAndCacheMetadata();
    };

    initializeMetadata();
  }, [ready]);

  const fetchAndCacheMetadata = async () => {
    try {
      setLoading(true);
      console.log('🚀 Starting metadata fetch...');
      
      if (!ready) {
        console.log('❌ DataConnect not ready yet');
        return;
      }

      console.log('📡 Executing FetchMetadata query...');
      const result = await executeQuery('FetchMetadata');
      console.log('📊 Raw query result:', result);

      if (result?.rows) {
        console.log('🔍 Direct SQL rows:', result.rows);
      }

      if (!result?.data?.bookingMetadatas) {
        console.error('❌ Invalid response structure:', {
          hasData: !!result?.data,
          dataKeys: Object.keys(result?.data || {}),
          fullResult: result
        });
        throw new Error('Invalid metadata response');
      }

      const metadata = result.data.bookingMetadatas;
      console.log('📋 Metadata entries:', metadata.length);
      console.log('🔍 First few entries:', metadata.slice(0, 3));

      console.log('🔄 Processing metadata...');
      const uniqueValues = metadata.reduce((acc, item) => {
        if (item.agent) {
          acc.agents.add({
            name: item.agent.trim(),
            count: item.bookingCount || 0
          });
        }
        if (item.type) acc.types.add(item.type.trim());
        if (item.option) acc.options.add(item.option.trim());
        if (item.location) acc.locations.add(item.location.trim());
        return acc;
      }, {
        agents: new Set(),
        types: new Set(),
        options: new Set(),
        locations: new Set()
      });

      const processedMetadata = {
        agents: [...uniqueValues.agents].sort((a, b) => a.name.localeCompare(b.name)),
        types: [...uniqueValues.types].sort(),
        options: [...uniqueValues.options].sort(),
        locations: [...uniqueValues.locations].sort()
      };

      console.log('✅ Final processed metadata:', {
        agentCount: processedMetadata.agents.length,
        typeCount: processedMetadata.types.length,
        optionCount: processedMetadata.options.length,
        locationCount: processedMetadata.locations.length,
        allAgents: processedMetadata.agents.map(a => a.name)
      });

      setMetadata(processedMetadata);
      setLastUpdated(new Date().toISOString());

    } catch (err) {
      console.error('❌ Error in fetchAndCacheMetadata:', {
        error: err,
        message: err.message,
        stack: err.stack
      });
      setError(err.message);
    } finally {
      setLoading(false);
      console.log('🏁 Fetch operation completed');
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">Booking Metadata</Typography>
        <Box>
          <Button 
            variant="contained" 
            onClick={fetchAndCacheMetadata}
            sx={{ mr: 2 }}
          >
            Refresh Data
          </Button>
          {lastUpdated && (
            <Typography variant="caption" color="text.secondary">
              Last updated: {new Date(lastUpdated).toLocaleString()}
            </Typography>
          )}
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <MetadataSection title="Agents" items={metadata.agents} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetadataSection title="Types" items={metadata.types} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetadataSection title="Options" items={metadata.options} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetadataSection title="Locations" items={metadata.locations} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MetadataDisplay; 