import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MonthlyParticipantsByTourChart = () => {
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });
    const [selectedTour, setSelectedTour] = useState('');
    const [tourOptions, setTourOptions] = useState([]);
    const [participantsMap, setParticipantsMap] = useState({});

    useEffect(() => {
        const years = [2021, 2022, 2023, 2024];
        let localParticipantsMap = {};

        const fetchData = async () => {
            for (const year of years) {
                const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=Bookings_${year}!A2:G`;
                try {
                    const response = await fetch(functionUrl);
                    if (!response.ok) throw new Error('Failed to fetch');
                    const data = await response.json();

                    data.forEach(row => {
                        const date = new Date(row[0]);
                        const month = date.getMonth();
                        const tourTitle = row[3];
                        const participants = parseInt(row[5], 10) || 0;

                        if (!localParticipantsMap[tourTitle]) {
                            localParticipantsMap[tourTitle] = {};
                        }
                        if (!localParticipantsMap[tourTitle][year]) {
                            localParticipantsMap[tourTitle][year] = new Array(12).fill(0);
                        }

                        localParticipantsMap[tourTitle][year][month] += participants;
                    });
                } catch (error) {
                    console.error(`Error fetching data for ${year}:`, error);
                }
            }

            setParticipantsMap(localParticipantsMap);
            setTourOptions(Object.keys(localParticipantsMap));
            setSelectedTour(Object.keys(localParticipantsMap)[0]); // Default to the first tour
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!selectedTour || !participantsMap[selectedTour]) return;

        const datasets = Object.entries(participantsMap[selectedTour]).map(([year, participants]) => ({
            label: `Year ${year}`,
            data: participants,
            borderColor: getRandomColor(),
            backgroundColor: getRandomColor(),
            fill: false,
        }));

        setChartData({
            labels: chartData.labels,
            datasets,
        });
    }, [selectedTour, participantsMap]);

    const handleTourChange = (event) => {
        setSelectedTour(event.target.value);
    };

    // Utility function to generate random colors for chart lines
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    return (
        <div>
            <h2>Monthly Participants by Tour</h2>
            <FormControl fullWidth margin="normal">
                <InputLabel id="tour-select-label">Select a Tour</InputLabel>
                <Select
                  labelId="tour-select-label"
                  id="tour-select"
                  value={selectedTour}
                  label="Select a Tour"
                  onChange={handleTourChange}
                >
                  {tourOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
            </FormControl>
            <Line data={chartData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
        </div>
    );
};

export default MonthlyParticipantsByTourChart;