import React, { useEffect, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const TodayToursDashboard = () => {
    const [toursToday, setToursToday] = useState([]);

    useEffect(() => {
        const fetchToursToday = async () => {
            const functionUrl = 'https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=Bookings_2024!A2:G';
            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const rows = await response.json();

                const today = new Date().toISOString().slice(0, 10); // Gets today's date in YYYY-MM-DD format

                const filteredRows = rows.filter(row => row[6] === today); // Assuming row[6] contains the date in YYYY-MM-DD format
                setToursToday(filteredRows);
            } catch (error) {
                console.error('Error fetching tours for today:', error);
            }
        };

        fetchToursToday();
    }, []);

    return (
        <div style={{ margin: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Tours Departing Today
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="tours table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Company/Agent</TableCell>
                            <TableCell align="right">Total Revenue</TableCell>
                            <TableCell align="right">Total Participants</TableCell>
                            <TableCell align="right">Tour Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {toursToday.length > 0 ? (
                            toursToday.map((tour, index) => (
                                <TableRow key={index}>
                                    <TableCell>{tour[3]}</TableCell> {/* Adjust index as needed */}
                                    <TableCell>{tour[1]} / {tour[2]}</TableCell> {/* Adjust indices as needed */}
                                    <TableCell align="right">${tour[4]}</TableCell>
                                    <TableCell align="right">{tour[5]}</TableCell>
                                    <TableCell align="right">{tour[6]}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} align="center">No tours departing today.</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TodayToursDashboard;
