import React from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import Box from '@mui/material/Box';
import theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import Footer from './components/FooterDesktop';
import FBTTPage from './pages/fbtt';
import ActivityManager from './pages/ActivityManager';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import SelectedTours from './pages/HomePageTours';
import LocalDeals from './pages/LocalDeals';
import Transfers from './pages/Transfers';
import Contact from './pages/Contact';
import DashboardPage from './pages/Dashboard';
import DashboardPage2 from './pages/Dashboard2'; // Add this import
import AdminPage from './pages/Admin';
import TemplateTourPage from './components/TemplateTourPage';
import useMediaQuery from '@mui/material/useMediaQuery';
import VideoBanner from './components/VideoBanner';
import ProtectedRoute from './components/ProtectedRoute';
import i18n from './components/i18n';
import { I18nextProvider } from 'react-i18next';
import TermsAndConditions from './pages/Terms';
import WhatsAppButton from './components/WhatsApp';
import MobileMenu from './components/MobileMenu';
import ReviewsPage from './pages/reviews';
import TestPage from './pages/TestPage';
import MenuEditor from './pages/MenuEditor/MenuEditor';
import GuestMenu from './pages/GuestMenu/GuestMenu'; // Updated import
import { cleanupClarity } from './utils/cleanupClarity';
import { useEffect } from 'react';
import { DataConnectProvider } from './context/DataConnectContext';
import SardonyxDashboard from './pages/SardonyxDashboard';

const Layout = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Add this line to detect mobile devices
  const location = useLocation();
  const showSideMenu = false; // Set to false to hide the side menu

  return (
    <Box sx={{ 
      display: 'flex', 
      flexGrow: 1,
      flexDirection: isMobile ? 'column' : 'row', // Adjust layout direction for mobile devices
    }}>
      {showSideMenu && <SideMenu />}
      <Box component="main" sx={{ 
        flexGrow: 1, 
        p: isMobile ? 1 : 3, // Adjust padding for mobile devices
      }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/fbtt" element={<I18nextProvider i18n={i18n}><FBTTPage /></I18nextProvider>} />
          <Route path="/activity-manager" element={<ProtectedRoute><ActivityManager /></ProtectedRoute>} />
          <Route path="/selected-tours" element={<SelectedTours />} />
          <Route path="/tour-info" element={<TemplateTourPage />} />
          <Route path="/tour/:tourId" element={<TemplateTourPage />} />
          <Route path="/local-deals" element={<LocalDeals />} />
          <Route path="/transfers" element={<Transfers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/reviews" element={<ProtectedRoute><ReviewsPage /></ProtectedRoute>} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/menu-editor" element={<ProtectedRoute><MenuEditor /></ProtectedRoute>} />
          <Route path="/guest-menu" element={<GuestMenu />} />
          <Route path="/dashboard2" element={<DashboardPage2 />} /> {/* Modified this line */}
          <Route path="/SardonyxDashboard" element={<SardonyxDashboard />} /> {/* New route for Sardonyx Dashboard */}
        </Routes>
      </Box>
    </Box>
  );
};

const FooterConditional = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  return isDesktop ? <Footer /> : null;
};

function App() {
  useEffect(() => {
    cleanupClarity();
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <DataConnectProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe 
            src="https://www.googletagmanager.com/ns.html?id=GTM-5W5ZW3CV"
            height="0" 
            width="0" 
            style={{display: 'none', visibility: 'hidden'}}
            title="Google Tag Manager"
          ></iframe>
        </noscript>
        {/* End Google Tag Manager (noscript) */}
        <Router>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh',
            backgroundColor: '#E6F5FB', // Add this line to set the background color
            padding: isMobile ? '0 16px' : '0', // Add padding for mobile devices
          }}>
            <Header />
            {isMobile && <MobileMenu />}
            <VideoBanner />
            <Layout />
            <FooterConditional />
            <WhatsAppButton />
          </Box>
        </Router>
        <Toaster />
      </ThemeProvider>
    </DataConnectProvider>
  );
}

export default App;