import React, { useState, useCallback } from 'react';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { doc, writeBatch, getDoc, setDoc } from "firebase/firestore";
import { db } from '../firebase/firebase';
import { Button, TextField, Box, LinearProgress, Typography } from '@mui/material';

// Constants for batch processing
const BATCH_SIZE = 450;
const CHUNK_SIZE = 2000;
const DELAY_BETWEEN_BATCHES = 100;
const MAX_RETRIES = 3;

// Helper functions first
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const exponentialBackoff = (retryCount) => {
  return Math.min(1000 * Math.pow(2, retryCount), 10000);
};

const formatDate = (dateString) => {
  if (!dateString) {
    console.warn('Empty date string');
    return {};
  }
  
  try {
    let day, month, year;
    
    if (dateString.includes('/')) {
      [day, month, year] = dateString.split('/');
    } else if (dateString.includes('-')) {
      [day, month, year] = dateString.split('-');
    } else {
      console.warn('Invalid date format:', dateString);
      return {};
    }
    
    if (year.length === 2) {
      year = '20' + year;
    }
    
    day = day.padStart(2, '0');
    month = month.padStart(2, '0');
    
    return {
      formattedDate: `${year}-${month}-${day}`,
    };
  } catch (error) {
    console.warn('Error formatting date:', dateString, error);
    return {};
  }
};

const parsePax = (paxString) => {
  if (!paxString) return 0;
  return paxString.split('+')
    .map(num => parseInt(num) || 0)
    .reduce((sum, num) => sum + num, 0);
};

const parseAmount = (amountString) => {
  if (!amountString) return null;
  const amount = parseFloat(String(amountString).replace(/[^0-9.-]+/g, ''));
  return isNaN(amount) ? null : amount;
};

// Then the field mappings
const ESSENTIAL_FIELDS = {
  'BookingNumber': 'bookingRef',
  'ReservationNo': 'customerRef',
  'Date': 'date',
  'Agency': 'agent',
  'Agent': 'agent',
  'Pax': 'pax',
  'Amount': 'amount',
  'TransType': 'type',
  'DestinationLocation': 'location',
  'Option': 'option'
};

// Then formatRowData
const formatRowData = (row) => {
  const formattedRow = {};
  
  for (const [headerName, fieldName] of Object.entries(ESSENTIAL_FIELDS)) {
    const value = row[headerName];
    if (value === undefined || value === '') continue;

    switch (fieldName) {
      case 'amount':
        const amount = parseAmount(value);
        if (amount !== null) formattedRow[fieldName] = amount;
        break;
      case 'pax':
        const pax = parsePax(value);
        if (pax !== 0) formattedRow[fieldName] = pax;
        break;
      case 'date':
        const { formattedDate } = formatDate(value);
        if (formattedDate) formattedRow[fieldName] = formattedDate;
        break;
      default:
        if (value && value.trim()) {
          formattedRow[fieldName] = value.trim();
        }
    }
  }

  return formattedRow;
};

// Then your ExcelUploader component
const ExcelUploader = () => {
  const [rowCount, setRowCount] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [collectionName, setCollectionName] = useState('');
  const [isCancelled, setIsCancelled] = useState(false);
  const [feedback, setFeedback] = useState('');

  const handleCollectionNameChange = (e) => {
    setCollectionName(e.target.value);
  };

  const handleFileUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      if (!file) {
        setFeedback('No file selected');
        return;
      }

      if (!collectionName.trim()) {
        setFeedback('Please enter a collection name before uploading');
        return;
      }

      setIsUploading(true);
      setIsCancelled(false);
      setFeedback(`Starting file upload to collection: ${collectionName}`);

      const fileReader = new FileReader();

      fileReader.onload = async (event) => {
        try {
          let rows;
          let headers;

          // Handle different file types
          if (file.name.endsWith('.csv')) {
            // Existing CSV handling
            const csvData = event.target.result;
            const parsedData = Papa.parse(csvData, {
              header: true,
              skipEmptyLines: true,
              dynamicTyping: true,
            });
            headers = parsedData.meta.fields;
            rows = parsedData.data;
          } else {
            // Excel handling
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet, {
              raw: false,
              dateNF: 'dd/mm/yyyy',
              defval: null,  // Use null for empty cells
              blankrows: false
            });
            
            // Get headers from the first row that has data
            if (jsonData.length > 0) {
              headers = Object.keys(jsonData[0]);
              rows = jsonData;
            } else {
              throw new Error('No data found in file');
            }
          }

          if (!headers || headers.length === 0) {
            throw new Error('No headers found in file');
          }

          const rowCount = rows.length;

          setRowCount(rowCount);
          setFeedback(`File loaded. Total rows: ${rowCount}. Collection: ${collectionName}`);

          const dateFieldName = headers[4]; // Use column E as date field
          let processedRows = 0;

          const processChunk = async (startIndex) => {
            if (isCancelled) {
              setFeedback('Upload cancelled');
              return;
            }

            const endIndex = Math.min(startIndex + CHUNK_SIZE, rows.length);
            const chunk = rows.slice(startIndex, endIndex);

            await processRows(chunk, collectionName, dateFieldName);
            await delay(500); // Add delay between chunks

            processedRows += chunk.length;
            const progress = Math.round((processedRows / rowCount) * 100);
            setUploadProgress(progress);
            setFeedback(`Processed ${processedRows} of ${rowCount} rows (${progress}%)`);

            if (endIndex < rows.length) {
              setTimeout(() => processChunk(endIndex), 1000); // Add delay before next chunk
            } else {
              setFeedback('All data uploaded successfully!');
              setIsUploading(false);
              setUploadProgress(0);
            }
          };

          await processChunk(0);
        } catch (error) {
          console.error('Error processing file:', error);
          setFeedback(`Error processing file: ${error.message}`);
          setIsUploading(false);
        }
      };

      fileReader.onerror = (error) => {
        console.error('FileReader error:', error);
        setFeedback(`Error reading file: ${error.message}`);
        setIsUploading(false);
      };

      // Change reading method based on file type
      if (file.name.endsWith('.csv')) {
        fileReader.readAsText(file);
      } else {
        fileReader.readAsArrayBuffer(file);
      }
    },
    [collectionName, isCancelled]
  );

  const processRows = async (rows, collectionName, dateFieldName) => {
    try {
      if (!collectionName.trim()) {
        throw new Error('Collection name is empty');
      }

      let batch = writeBatch(db);
      let batchCount = 0;
      let processedCount = 0;
      
      // Create a more efficient data structure for processing
      const groupedData = new Map();
      
      // First pass: Group and format all data without any database reads
      rows.forEach(row => {
        const dateValue = row[dateFieldName];
        const { formattedDate } = formatDate(dateValue);
        if (!formattedDate) return;
        
        const formattedRow = formatRowData(row);
        const key = `${formattedRow.bookingRef}_${formattedDate}`;
        
        if (!groupedData.has(formattedDate)) {
          groupedData.set(formattedDate, new Map());
        }
        groupedData.get(formattedDate).set(key, formattedRow);
      });

      // Second pass: Batch write the data
      for (const [dateKey, bookingsMap] of groupedData) {
        const docRef = doc(db, collectionName, dateKey);
        
        // Convert Map to array
        const dataArray = Array.from(bookingsMap.values());
        
        batch.set(docRef, {
          data: dataArray,
          lastUpdated: new Date().toISOString(),
          count: dataArray.length
        });

        batchCount++;
        processedCount += dataArray.length;

        // Commit batch when size limit is reached
        if (batchCount === BATCH_SIZE) {
          await batch.commit();
          await delay(DELAY_BETWEEN_BATCHES);
          batch = writeBatch(db);
          batchCount = 0;
          setFeedback(`Processed ${processedCount} records...`);
        }
      }

      // Commit any remaining batches
      if (batchCount > 0) {
        await batch.commit();
        setFeedback(`Completed! Processed ${processedCount} total records`);
      }

    } catch (error) {
      console.error('Error processing rows:', error);
      setFeedback(`Error processing rows: ${error.message}`);
      throw error;
    }
  };

  const cancelUpload = () => {
    setIsCancelled(true);
    setIsUploading(false);
    setUploadProgress(0);
    setFeedback('Upload cancelled');
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Excel File Uploader
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          fullWidth
          label="Collection Name"
          value={collectionName}
          onChange={handleCollectionNameChange}
          disabled={isUploading}
        />

        <Button
          variant="contained"
          component="label"
          disabled={isUploading || !collectionName.trim()}
        >
          Upload File
          <input 
            type="file" 
            accept=".csv,.xlsx,.xls" 
            onChange={handleFileUpload} 
            hidden 
          />
        </Button>

        {isUploading && (
          <>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Button variant="outlined" color="error" onClick={cancelUpload}>
              Cancel Upload
            </Button>
          </>
        )}

        {feedback && (
          <Typography color="text.secondary" sx={{ mt: 2 }}>
            {feedback}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ExcelUploader;
