// Import Statements
import React, { useState } from 'react';
import {
  Container, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
  Button, TextField, Box, Typography, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Card, CardContent, CardMedia, Select, MenuItem, InputLabel
} from '@mui/material';
import { cruiseData } from '../../components/fbtt database/cruise.js';
import { stayoverTours } from '../../components/fbtt database/stayover.js';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../components/LanguageSelector';
import { db } from '../../config/firebase/firebase'; // Import Firestore
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions

const TouristSurveyForm = () => {
  // State Management
  const [touristType, setTouristType] = useState('');
  const [selectedTour, setSelectedTour] = useState('');
  const [formData, setFormData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCruiseLine, setSelectedCruiseLine] = useState('');
  const [selectedStayoverType, setSelectedStayoverType] = useState('');
  const [availableTours, setAvailableTours] = useState([]);
  const { t } = useTranslation();

  // List of questions
  const questions = [
    { label: t('Rate your overall experience'), name: 'overallExperience' },
    { label: t('Rate the tour guide'), name: 'tourGuide' },
    { label: t('Rate the cleanliness of the bus'), name: 'busCleanliness' },
    { label: t('Rate the punctuality of the tour'), name: 'tourPunctuality' },
    { label: t('Rate the quality of the attractions visited'), name: 'attractionQuality' },
    { label: t('Rate the value for money'), name: 'valueForMoney' },
    { label: t('Rate the driver'), name: 'driverRating' },
    { label: t('Rate the safety measures (e.g., were safety instructions given and adhered to?)'), name: 'safetyMeasures' },
    { label: t('Rate your overall satisfaction with the tour'), name: 'overallSatisfaction' },
    { label: t('Rate the likelihood of recommending our tour to others'), name: 'likelihoodToRecommend' },
  ];

  // Handle input changes
  const handleTypeChange = (e) => {
    setTouristType(e.target.value);
    setSelectedCruiseLine('');
    setSelectedStayoverType('');
    setSelectedTour('');
    setAvailableTours([]);
  };

  const handleCruiseLineChange = (event) => {
    const cruiseLineName = event.target.value;
    setSelectedCruiseLine(cruiseLineName);
    setAvailableTours(cruiseData[cruiseLineName].tours.filter(tour => tour !== 'Trolley Tour Including Dispatch'));
  };

  const handleStayoverTypeChange = (event) => {
    const stayoverType = event.target.value;
    setSelectedStayoverType(stayoverType);
    setAvailableTours(stayoverTours[stayoverType].tours);
  };

  const handleTourChange = (e) => {
    setSelectedTour(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const completeFormData = {
      ...formData,
      touristType,
      selectedCruiseLine,
      selectedStayoverType,
      selectedTour,
    };
    console.log("Submitting form data:", completeFormData);

    try {
      await addDoc(collection(db, 'surveys'), completeFormData);
      console.log("Document successfully written!");
      setOpenDialog(true);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  // Close the Dialog
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '100px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <CardMedia
          component="img"
          style={{ maxWidth: '50%', height: 'auto' }}
          image="/images/fbtt_logo.jpeg"
          alt="Company Logo"
        />
        <LanguageSelector />
      </Box>
      <Card>
        <CardContent style={{ backgroundColor: '#fff' }}>
          <Typography variant="h5" gutterBottom>
            {t('Tourist Survey')}
          </Typography>

          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">{t('What type of tourist are you?')}</FormLabel>
            <RadioGroup
              aria-label="tourist type"
              name="touristType"
              value={touristType}
              onChange={handleTypeChange}
              row
            >
              <FormControlLabel value="Cruise" control={<Radio />} label={t('Cruise Tourist')} />
              <FormControlLabel value="Stayover" control={<Radio />} label={t('Stayover Tourist')} />
            </RadioGroup>
          </FormControl>

          {touristType === 'Cruise' && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="cruise-line-select-label">{t('Select the Cruise Line')}</InputLabel>
              <Select
                labelId="cruise-line-select-label"
                id="cruise-line-select"
                value={selectedCruiseLine}
                label={t('Select the Cruise Line')}
                onChange={handleCruiseLineChange}
              >
                {Object.keys(cruiseData).map((cruiseLine, index) => (
                  <MenuItem key={index} value={cruiseLine}>{cruiseData[cruiseLine].name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {touristType === 'Stayover' && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="stayover-type-select-label">{t('Select the Stayover Type')}</InputLabel>
              <Select
                labelId="stayover-type-select-label"
                id="stayover-type-select"
                value={selectedStayoverType}
                label={t('Select the Stayover Type')}
                onChange={handleStayoverTypeChange}
              >
                {Object.keys(stayoverTours).map((stayoverType, index) => (
                  <MenuItem key={index} value={stayoverType}>{stayoverTours[stayoverType].name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {(selectedCruiseLine || selectedStayoverType) && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="tour-select-label">{t('Select the Tour')}</InputLabel>
              <Select
                labelId="tour-select-label"
                id="tour-select"
                value={selectedTour}
                label={t('Select the Tour')}
                onChange={handleTourChange}
              >
                {availableTours.map((tour, index) => (
                  <MenuItem key={index} value={tour}>{tour}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {questions.map((question, index) => (
            <Box key={index} mb={4}>
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">{t(question.label)}</FormLabel>
                <RadioGroup
                  aria-label={question.name}
                  name={question.name}
                  onChange={handleInputChange}
                  row
                >
                  {[...Array(10).keys()].map(i => (
                    <FormControlLabel key={i} value={String(i + 1)} control={<Radio />} label={String(i + 1)} />
                  ))}
                </RadioGroup>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label={t(`Additional comments on ${question.label.toLowerCase()}`)}
                  name={`${question.name}Comments`}
                  multiline
                  rows={2}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Box>
          ))}

          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {t('Submit')}
          </Button>

          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t('Thank You!')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t('Thank you for taking the time to complete our survey. Your feedback is valuable to us and helps improve our services.')}
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                {t('If you enjoyed your experience, please consider leaving us a review on Google or TripAdvisor. It only takes a minute and helps us a lot. Thank you for your support!')}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t('Close')}
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
    </Container>
  );
};

export default TouristSurveyForm;