import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Card, CardContent, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { db, functions } from '../../config/firebase/firebase'; 
import { collection, doc, setDoc, getDocs, getDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { httpsCallable } from 'firebase/functions';

function ActivityManager() {
  const [activities, setActivities] = useState([]);
  const [activitySelections, setActivitySelections] = useState({});
  const [rewrittenDescriptions, setRewrittenDescriptions] = useState({});
  const [editingActivity, setEditingActivity] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [openRewriteDialog, setOpenRewriteDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState('');

  useEffect(() => {
    const fetchActivities = async () => {
      const response = await fetch('https://us-central1-curacao-tours.cloudfunctions.net/fetchTrekksoftActivities');
      if (response.ok) {
        const data = await response.json();
        setActivities(data);
      } else {
        console.error('Network response was not ok');
      }
    };

    fetchActivities();
  }, []);

  useEffect(() => {
    const fetchSavedSelections = async () => {
      const selectionsSnapshot = await getDocs(collection(db, 'activitySelections'));
      const fetchedSelections = {};
      selectionsSnapshot.forEach(doc => {
        fetchedSelections[doc.id] = doc.data();
      });
      setActivitySelections(fetchedSelections);
    };

    if (activities.length > 0) {
      fetchSavedSelections();
    }
  }, [activities]);

  useEffect(() => {
    const fetchRewrittenDescriptions = async () => {
      const rewrittenDocs = await getDocs(collection(db, 'rewrittenTourDescriptions'));
      const descriptions = {};
      rewrittenDocs.forEach(doc => {
        descriptions[doc.id] = doc.data();
      });
      setRewrittenDescriptions(descriptions);
    };

    fetchRewrittenDescriptions();
  }, []);

  const handleRewriteDescription = async (activity) => {
    setSelectedActivity(activity);
    setOpenRewriteDialog(true);
  };

  const handleCloseRewriteDialog = () => {
    setOpenRewriteDialog(false);
    setSelectedActivity(null);
    setAdditionalInfo('');
  };

  const handleSubmitRewrite = async () => {
    const rewriteDescription = httpsCallable(functions, 'rewriteTourDescriptions');
    try {
      const activity = selectedActivity;
      const combinedContent = `Title: ${activity.title}\nShort Title: ${activity.titleShort}\nPrice: ${activity.fromPrice.amount} ${activity.fromPrice.currency}\nDescription: ${activity.description}\nShort Description: ${activity.descriptionShort || ''}\nAdditional Information: ${additionalInfo}`.replace(/<[^>]+>/g, '');

      const requestData = {
        activityId: activity.id,
        messages: [{ role: "user", content: combinedContent }]
      };

      // Send the request and wait for the response
      await rewriteDescription(requestData);
      
      // Display thank you message
      toast.success('Thank you for submitting the rewrite request!');
      handleCloseRewriteDialog();
    } catch (error) {
      console.error('Error submitting rewrite request:', error);
      // Check if it's the specific "Response is missing data field" error
      if (error.message.includes('Response is missing data field')) {
        // This error is expected, so we'll treat it as a success
        toast.success('Thank you for submitting the rewrite request!');
        handleCloseRewriteDialog();
      } else {
        // For other errors, show an error message
        toast.error('Failed to submit rewrite request. Please try again.');
      }
    }
  };

  const handleSelectActivity = (activityId, category) => {
    setActivitySelections(prev => ({
      ...prev,
      [activityId]: {
        ...prev[activityId],
        [category]: !prev[activityId]?.[category]
      }
    }));
  };

  const handleSaveSelections = async () => {
    const activityDetailsCollection = collection(db, 'activityDetails');
    const activitySelectionsCollection = collection(db, 'activitySelections');
  
    try {
      const savePromises = [];
  
      for (const [activityId, selection] of Object.entries(activitySelections)) {
        const activity = activities.find(a => a.id.toString() === activityId);
        if (activity) {
          // Prepare activity data with image URLs
          const activityData = {
            ...activity,
            headerImage: activity.images.header?.M || '', // Fallback to empty string if undefined
            teaserImage: activity.images.teaser?.M || '', // Fallback to empty string if undefined
            galleryImages: activity.images.gallery?.map(img => img.M) || [], // Fallback to empty array if undefined
            originalPrice: activity.fromPrice // Include the original price directly
          };
  
          // Save activity details with images
          const activityDetailsDoc = doc(activityDetailsCollection, activityId);
          savePromises.push(setDoc(activityDetailsDoc, activityData));
  
          // Save selection state
          const selectionDoc = doc(activitySelectionsCollection, activityId);
          savePromises.push(setDoc(selectionDoc, selection));
        }
      }
  
      await Promise.all(savePromises);
      toast.success('Website updated!');
    } catch (error) {
      console.error('Error saving activity details:', error);
      toast.error('Failed to update the website.');
    }
  };

  const handleUpdateActivityDetails = async () => {
    const response = await fetch('https://us-central1-curacao-tours.cloudfunctions.net/fetchTrekksoftActivities');
    if (response.ok) {
      const data = await response.json();
      const activityDetailsCollection = collection(db, 'activityDetails');
      const savePromises = data.map(activity => {
        const activityData = {
          ...activity,
          headerImage: activity.images.header?.M || '',
          teaserImage: activity.images.teaser?.M || '',
          galleryImages: activity.images.gallery?.map(img => img.M) || [],
          originalPrice: activity.fromPrice
        };
        const activityDetailsDoc = doc(activityDetailsCollection, activity.id.toString());
        return setDoc(activityDetailsDoc, activityData);
      });
      try {
        await Promise.all(savePromises);
        toast.success('Activity details updated!');
      } catch (error) {
        console.error('Error updating activity details:', error);
        toast.error('Failed to update activity details.');
      }
    } else {
      console.error('Network response was not ok');
    }
  };

  const handleEditActivity = async (activityId) => {
    const docRef = doc(db, 'rewrittenTourDescriptions', activityId.toString());
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      setEditingActivity(activityId);
      setEditedData(docSnap.data());
    } else {
      toast.error('No rewritten description found for this activity.');
    }
  };

  const handleSaveEdit = async () => {
    try {
      const docRef = doc(db, 'rewrittenTourDescriptions', editingActivity.toString());
      await setDoc(docRef, editedData);
      toast.success('Activity description updated successfully!');
      setEditingActivity(null);
    } catch (error) {
      console.error('Error updating activity description:', error);
      toast.error('Failed to update activity description.');
    }
  };

  const handleInputChange = (field, value) => {
    setEditedData(prev => ({ ...prev, [field]: value }));
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>Select Activities for the Main Page</Typography>
          <Button variant="contained" color="primary" onClick={handleUpdateActivityDetails} sx={{ mb: 2 }}>
            Update Activity Details
          </Button>
          <TableContainer component={Paper}>
            <Table aria-label="activities table">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell align="center">Category</TableCell>
                  <TableCell align="center">Price</TableCell>
                  <TableCell align="center">Header Image</TableCell>
                  <TableCell align="center">Teaser Image</TableCell>
                  <TableCell align="center">Gallery Images</TableCell>
                  <TableCell align="center">Selections</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activities.map((activity) => (
                  <TableRow key={activity.id}>
                    <TableCell component="th" scope="row">
                      {`${activity.title} (ID: ${activity.id})`} {/* Concatenating the title with the ID */}
                    </TableCell>
                    <TableCell align="center">{activity.category?.name}</TableCell>
                    <TableCell align="center">{activity.fromPrice?.amount} {activity.fromPrice?.currency}</TableCell>
                    <TableCell align="center">
                      {activity.images.header && (
                        <div style={{ maxWidth: '75px', overflow: 'hidden' }}>
                          <img src={activity.images.header.M} alt={`${activity.title} header`} style={{ width: '100%', height: 'auto' }} />
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {activity.images.teaser && (
                        <div style={{ maxWidth: '75px', overflow: 'hidden' }}>
                          <img src={activity.images.teaser.M} alt={`${activity.title} teaser`} style={{ width: '100%', height: 'auto' }} />
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1px', maxWidth: '150px' }}>
                        {activity.images.gallery && activity.images.gallery.map((galleryImage, index) => (
                          <div key={index} style={{ width: '50px', height: '50px', overflow: 'hidden' }}>
                            <img src={galleryImage.M} alt={`${activity.title} gallery ${index}`} style={{ width: '100%', height: 'auto' }} />
                          </div>
                        ))}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <Grid container direction="column" alignItems="flex-start">
                        <Grid item>
                          <Checkbox checked={!!activitySelections[activity.id]?.homePage} onChange={() => handleSelectActivity(activity.id, 'homePage')} />
                          Home Page
                        </Grid>
                        <Grid item>
                          <Checkbox checked={!!activitySelections[activity.id]?.mostPopular} onChange={() => handleSelectActivity(activity.id, 'mostPopular')} />
                          Most Popular
                        </Grid>
                        <Grid item>
                          <Checkbox
                            checked={!!activitySelections[activity.id]?.transfers}
                            onChange={() => handleSelectActivity(activity.id, 'transfers')}
                          />
                          Transfers
                        </Grid>
                        <Grid item>
                          <Checkbox
                            checked={!!activitySelections[activity.id]?.localDeals}
                            onChange={() => handleSelectActivity(activity.id, 'localDeals')}
                          />
                          Local Deals
                        </Grid>

                        <Grid item>
                          <Checkbox checked={!!activitySelections[activity.id]?.specialOffers} onChange={() => handleSelectActivity(activity.id, 'specialOffers')} />
                          Special Offers
                        </Grid>
                        <Grid item>
                          <Checkbox checked={!!activitySelections[activity.id]?.displayInMenu} onChange={() => handleSelectActivity(activity.id, 'displayInMenu')} />
                          Display in Menu
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="center">
                      <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => handleRewriteDescription(activity)}
                      >
                        Rewrite Description with AI
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleEditActivity(activity.id)}
                        disabled={!rewrittenDescriptions[activity.id]}
                      >
                        {rewrittenDescriptions[activity.id] ? 'Edit' : 'No Description'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="contained" color="primary" onClick={handleSaveSelections} sx={{ mt: 2 }}>Save Selections</Button>
        </CardContent>
      </Card>
      <Dialog open={!!editingActivity} onClose={() => setEditingActivity(null)} maxWidth="md" fullWidth>
        <DialogTitle>Edit Activity Description</DialogTitle>
        <DialogContent>
          {Object.entries(editedData).map(([key, value]) => (
            <TextField
              key={key}
              label={key}
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              fullWidth
              multiline={typeof value === 'string' && value.length > 50}
              margin="normal"
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingActivity(null)}>Cancel</Button>
          <Button onClick={handleSaveEdit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openRewriteDialog} onClose={handleCloseRewriteDialog}>
        <DialogTitle>Additional Information for AI Rewrite</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="additionalInfo"
            label="Important Information"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRewriteDialog}>Cancel</Button>
          <Button onClick={handleSubmitRewrite} color="primary">
            Submit for Rewrite
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ActivityManager;