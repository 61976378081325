import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Box, Typography, Paper, List, ListItem, ListItemText, Chip, useTheme } from '@mui/material';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const locations = [
  { name: 'Playa Jeremi', coords: [12.3230, -69.1500], tour: 'Beach Escape Tour', highlights: ['Playa Piskado', 'Groot Knip', 'Cas Abou'] },
  { name: 'Grote Knip', coords: [12.3530, -69.1490], tour: 'Beach Escape Tour', highlights: ['Playa Piskado', 'Groot Knip', 'Cas Abou'] },
  { name: 'Cas Abao Beach', coords: [12.2508, -69.0993], tour: 'Beach Escape Tour', highlights: ['Playa Piskado', 'Groot Knip', 'Cas Abou'] },
  { name: 'Willemstad Harbor', coords: [12.1048, -68.9344], tour: 'SeaWorld Explorer' },
  { name: 'Downtown Willemstad', coords: [12.1048, -68.9344], tour: 'Trolley Train City Centre', highlights: ['Bolo di Batrei', 'Pietermaai Cathedral'] },
  { name: 'Hato Caves', coords: [12.1999, -68.9685], tour: 'Curaçao Island Tour', highlights: ['Curaçao Liqueur Distillery', 'Shete Boka National Park', 'Groot Knip'] },
  { name: 'Punda District', coords: [12.1048, -68.9344], tour: 'Historic Walking Tour', highlights: ['Queen Emma Bridge Pontoon Bridge', 'Fort Amsterdam', 'Local Market Place', 'Emanuel Synagogue'] },
  { name: 'Tugboat Beach', coords: [12.0834, -68.8574], tour: 'Half Day Snorkel Tour by Boat', highlights: ['Tugboat'] },
  { name: 'Caracas Bay', coords: [12.0813, -68.8541], tour: 'Half Day Snorkel Tour by Boat', highlights: ['Tugboat'] },
  { name: 'Spanish Waters', coords: [12.0784, -68.8610], tour: 'Sunset Cruise', highlights: ['Spanish Water'] },
  { name: 'Punda District', coords: [12.1048, -68.9344], tour: 'Punda Vibes E-bike Experience', highlights: ['Emma Bridge', 'Wilhelmina Plein', 'Punda'] },
  { name: 'Otrobanda District', coords: [12.1048, -68.9344], tour: 'Shopping Tour', highlights: ['Sambil', 'Otrobanda', 'Renaissance Shopping Mall'] },
  { name: 'Pietermaai District', coords: [12.1068, -68.9302], tour: 'Culinary Tour' },
  { name: 'Willemstad', coords: [12.1048, -68.9344], tour: 'City E-bike Tour' },
];

const CuracaoMap = () => {
  const [selectedTour, setSelectedTour] = useState(null);
  const theme = useTheme();

  const mapStyles = `
    .leaflet-control-attribution,
    .leaflet-control-zoom {
      display: none;
    }
    .leaflet-container {
      cursor: default !important;
    }
    .custom-map-container {
      border: 2px solid ${theme.palette.primary.main};
      border-radius: 8px;
      overflow: hidden;
    }
    .custom-popup .leaflet-popup-content-wrapper {
      background: ${theme.palette.background.paper};
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
    .custom-popup .leaflet-popup-tip {
      background: ${theme.palette.background.paper};
    }
  `;

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = mapStyles;
    document.head.append(style);
    return () => style.remove();
  }, [mapStyles]);

  const customIcon = new L.Icon({
    iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
    iconSize: [25, 25],
    iconAnchor: [12, 25],
    popupAnchor: [0, -25],
  });

  const tours = [...new Set(locations.map(location => location.tour))];

  const getColorForTour = (tour) => {
    const colors = [
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.error.main,
      theme.palette.warning.main,
      theme.palette.info.main,
      theme.palette.success.main,
    ];
    return colors[tours.indexOf(tour) % colors.length];
  };

  return (
    <Box sx={{ position: 'relative', height: '600px', width: '100%' }}>
      <MapContainer 
        center={[12.1696, -68.9900]} 
        zoom={11} 
        className="custom-map-container"
        style={{ height: '100%', width: '100%' }}
        zoomControl={false}
        dragging={false} // Disable dragging
        touchZoom={false} // Disable touch zoom
        doubleClickZoom={false} // Disable double-click zoom
        scrollWheelZoom={false} // Disable scroll wheel zoom
        boxZoom={false} // Disable box zoom
        keyboard={false} // Disable keyboard interactions
        whenCreated={(map) => {
          map.on('click', (e) => {
            e.originalEvent.preventDefault();
          });
        }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {locations.map((location, index) => (
          <Marker 
            key={index} 
            position={location.coords} 
            icon={customIcon}
            eventHandlers={{
              click: () => setSelectedTour(location.tour),
              mouseover: (e) => e.target.openPopup(),
              mouseout: (e) => e.target.closePopup(),
            }}
          >
            <Popup className="custom-popup">
              <Typography variant="subtitle2">{location.name}</Typography>
              <Typography variant="body2">{location.tour}</Typography>
              {location.highlights && (
                <Box mt={1}>
                  <Typography variant="body2" fontWeight="bold">Highlights:</Typography>
                  <List dense>
                    {location.highlights.map((highlight, idx) => (
                      <ListItem key={idx} disablePadding>
                        <ListItemText primary={<Typography variant="body2">{highlight}</Typography>} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      <Paper 
        elevation={3} 
        sx={{ 
          position: 'absolute', 
          top: 10, 
          right: 10, 
          maxWidth: 300, 
          maxHeight: '90%', 
          overflow: 'auto', 
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          p: 2,
          zIndex: 1000,
          borderRadius: 2,
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h6" gutterBottom>Tours</Typography>
        <List dense>
          {tours.map((tour, index) => (
            <ListItem 
              key={index} 
              disablePadding
              sx={{ 
                backgroundColor: selectedTour === tour ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)' },
                borderRadius: 1,
                mb: 1,
              }}
              onClick={() => setSelectedTour(tour)}
            >
              <ListItemText 
                primary={
                  <Box display="flex" alignItems="center">
                    <Chip 
                      size="small" 
                      sx={{ 
                        backgroundColor: getColorForTour(tour), 
                        mr: 1, 
                        width: 16, 
                        height: 16 
                      }} 
                    />
                    <Typography variant="body2">{tour}</Typography>
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default CuracaoMap;